<template>
  <div v-on-click-outside="closeDropdown" class="relative">
    <button
      class="flex h-10 w-full items-center rounded-lg border border-blue-200 px-4 py-1 text-sm text-blue-600 hover:bg-blue-200 md:flex"
      @click="dropdownOpen = !dropdownOpen"
    >
      {{ $t("locale", "", { locale }) }}
      <span class="grow" />
      <div
        class="mb-2 flex h-6 w-6 origin-[50%_70%] items-center justify-center transition-transform"
        :class="dropdownOpen ? 'rotate-180' : ''"
      >
        <div class="h-4 w-4 rotate-45 border-b border-r border-blue-600" />
      </div>
    </button>
    <div
      class="absolute top-11 z-50 w-full flex-col rounded-lg border border-gray-300 bg-white py-2 drop-shadow-xl"
      :class="dropdownOpen ? 'flex' : 'hidden'"
    >
      <a
        v-for="language in availableLocales"
        :key="`${language}`"
        class="px-4 py-2"
        :class="{
          'bg-gray-200': language == locale,
          'cursor-pointer': language != locale,
          'hover:bg-gray-300': language != locale,
        }"
        @click="setLanguage(language)"
      >
        {{ $t("locale", "", { locale: language }) }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { i18n } from "@/localization/i18n"
import { vOnClickOutside } from "@vueuse/components"

const { availableLocales, locale } = useI18n({ useScope: "global" })
const selectedLanguage = ref(locale)
const dropdownOpen = ref<boolean>(false)

watchEffect(() => {
  localStorage.setItem("tredco-app-language", `${selectedLanguage.value}`)
})

type Locale = "nb" | "en"

function setLanguage(lang: Locale) {
  selectedLanguage.value = lang
  i18n.global.locale.value = lang
  closeDropdown()

  // refresh the page in the case where t("...") is used only on component load
  location.reload()
}
function closeDropdown() {
  dropdownOpen.value = false
}
</script>
@/localization/i18n
