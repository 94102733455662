<template>
  <ModalDialog size="large" :show-modal="showModal" @close="closeModal">
    <template #header>
      <div class="flex items-center justify-center">
        <SparklesIcon class="mr-2 h-6 w-6 text-yellow-400" />
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{ $t("release_notes") }}
        </h3>
      </div>
    </template>

    <div class="flex flex-1 flex-col space-y-3 overflow-y-auto p-5">
      <div class="space-y-3">
        <template v-for="note in releaseNotes">
          <div v-if="note && note.type === 'feature'" :key="note.id" class="flex">
            <div class="mr-2 h-6 w-6 flex-shrink-0">
              <CheckCircleIcon class="h-full w-full text-green-500" />
            </div>
            <span>{{ note[language] }}</span>
          </div>
        </template>
      </div>
    </div>

    <hr>

    <div class="flex flex-1 flex-col space-y-3 overflow-y-auto p-5">
      <div class="space-y-3">
        <template v-for="note in releaseNotes">
          <div v-if="note && note.type === 'bug'" :key="note.id" class="flex">
            <div class="mr-2 h-6 w-6 flex-shrink-0">
              <BugAntIcon class="mr-2 h-6 w-6 text-red-500" />
            </div>
            <span>{{ note[language] }}</span>
          </div>
        </template>
      </div>
    </div>
  </ModalDialog>
</template>

<script setup lang="ts">
import type { ReleaseNotes } from "@/types"
import api from "@/api"
import { readItems } from "@directus/sdk"
import { BugAntIcon, CheckCircleIcon, SparklesIcon } from "@heroicons/vue/24/solid"

const { locale } = useI18n({ useScope: "global" })
const globalStore = useGlobalStore()
const language = locale as never as Ref<"en" | "no">

const releaseNotes = ref<ReleaseNotes[]>()
const showModal = ref<boolean>(false)

watch(
  () => globalStore.hydrated,
  async (newValue) => {
    if (newValue) {
      releaseNotes.value = (await api.client.request(
        readItems("release_notes", {
          filter: {
            date_implemented: {
              _gte: globalStore.user.release_notes_last_seen,
            },
            status: {
              _eq: "published",
            },
          },
          sort: ["-date_created"],
        }),
      )) as ReleaseNotes[]

      if (releaseNotes.value?.length !== 0) {
        showModal.value = true
        await api.users.updateMe({
          release_notes_last_seen: new Date().toISOString(),
        })
        globalStore.user = await api.users.getMe()
      }
    }
  },
  { immediate: true },
)

function closeModal() {
  showModal.value = false
}
</script>
