<template>
  <PageLayout :form-page="true">
    <template #header>
      <TopNav
        :title="$t('company-settings.company_informaiton')"
        :show-additiol-options="false"
        type="edit"
        @back="$router.back()"
      />
    </template>
    <template #default>
      <div>
        <div class="flex items-center justify-between py-4">
          <div>
            <div class="text-label-bold-1">
              {{ globalStore.tenant.name }}
            </div>
            <div class="text-label-bold-3 text-color-label-4">
              {{ `Org. nr.: ${globalStore.tenant?.company_registration_number}` }}
            </div>
          </div>
          <!-- <div>
            <Button type="secondary" @click="changeClick"
              >{{ $t("company-settings.change") }}
            </Button>
          </div> -->
        </div>

        <form class="space-y-3" @submit.prevent>
          <TextFieldVal
            v-model="globalStore.tenant.email"
            name="email"
            :label="$t('company-settings.your-account-settings.email')"
            :placeholder="$t('company-settings.your-account-settings.email')"
            type="email"
            :directus-context="directusContext"
            directus-property="email"
          />
          <TextFieldVal
            v-model="globalStore.tenant.street_address"
            name="address"
            :label="$t('street_address')"
            :placeholder="$t('street_address')"
            :directus-context="directusContext"
            directus-property="street_address"
          />
          <TextFieldVal
            v-model="globalStore.tenant.zip_code"
            name="postalCode"
            :label="$t('postal_code')"
            :placeholder="$t('postal_code')"
            type="number"
            :directus-context="directusContext"
            directus-property="zip_code"
          />
          <TextFieldVal
            v-model="globalStore.tenant.city"
            name="city"
            :label="$t('company-settings.company_city')"
            :placeholder="$t('company-settings.company_city')"
            :directus-context="directusContext"
            directus-property="city"
          />

          <CheckBox
            v-model="globalStore.tenant.vat_registered"
            name="visible_vat"
            :label="$t('company-settings.your-account-settings.vat_registered')"
            :directus-context="{
              ...directusContext,
              property: 'vat_registered',
            }"
          />

          <LimitedOptions
            :options="countries"
            :label="$t('country.country')"
            @selected-item="updateCountrySelection"
          />
        </form>
      </div>

      <div class="flex justify-center py-4">
        <Button
          class="w-3/4"
          type="danger"
          size="large"
          @click="
            () => {
              showModelToConfirmDelete = true
            }
          "
        >
          {{ $t("company-settings.delete") }}
        </Button>
      </div>
      <MobileDialog
        :loading="false"
        :title="$t('company-settings.delete_account_confirm')"
        :show="showModelToConfirmDelete"
        @y="globalStore.deleteTenant"
        @n="
          () => {
            showModelToConfirmDelete = false
          }
        "
      />
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { CountrySetting } from "@/config/countries"
import type { DirectusContext, LimitedOptionsSet } from "@/types"
import { useGlobalStore } from "@/stores/globalStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"

const showModelToConfirmDelete = ref(false)
const tenantSettingsStore = useTenantStore()
const globalStore = useGlobalStore()
const router = useRouter()
const countries = ref(
  getCountriesConfig(globalStore.tenant.country_code).limitedOptionsSet,
)

function updateCountrySelection(item: LimitedOptionsSet<CountrySetting>) {
  countries.value = getCountriesConfig(item.id).limitedOptionsSet

  if (item.value) {
    globalStore.tenant.country_code = item.id
    globalStore.tenant.currency = item.value?.currencyCode
    tenantSettingsStore.updateTenant({
      country_code: item.id,
      currency: item.value?.currencyCode,
    })
  }
}

const directusContext: DirectusContext = {
  collection: "tenants",
  id: globalStore.tenant.id,
}

async function changeClick() {
  await router.push({ name: "changeCompany" })
}
</script>
