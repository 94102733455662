<template>
  <BottomDrawerSlot :show-options="show" :title="$t('shared.ai.polish')" @close="onClose">
    <div
      ref="containerRef"
      class="flex flex-col gap-4 max-h-[75vh] overflow-y-auto min-h-[65vh] desktop:min-h-0"
    >
      <!-- Original Text Section -->
      <div class="flex flex-col gap-2">
        <p class="whitespace-pre-line text-label-3 text-color-label-2">
          {{ originalText }}
        </p>
        <div class="flex flex-wrap gap-2 mt-2">
          <ActionButtons
            :available-options="availableOptions"
            :is-loading="isLoading"
            @process="text => processText(text, originalText, -1)"
          />
        </div>
      </div>

      <!-- Generated Suggestions History -->
      <div
        v-for="(result, index) in suggestionHistory"
        :key="index"
        :ref="
          el => {
            if (index === suggestionHistory.length - 1)
              lastSuggestionRef = el as HTMLElement
          }
        "
        class="flex flex-col gap-2 p-3 transition-all duration-300 bg-2-static rounded-2xl"
        :class="{ 'opacity-50 scale-95': acceptingIndex === index }"
      >
        <div class="flex items-center justify-between gap-2">
          <div class="flex items-center gap-2">
            <IconButton @click="removeResult(index)" />
            <p class="text-label-2 text-color-label-4">
              {{ $t("shared.labels.suggestion") }} #{{ index + 1 }}
              <span class="text-caption-1 text-color-label-4">({{ result.operation }})</span>
            </p>
          </div>
          <IconButton @click="copyToClipboard(result.text, index)">
            <template v-if="copiedIndex === index">
              <CheckIcon class="w-4 h-4 text-semantic-success" />
            </template>
            <template v-else>
              <DocumentDuplicateIcon class="w-5 h-5" />
            </template>
          </IconButton>
        </div>
        <button
          class="p-4 text-left whitespace-pre-line transition-colors duration-200 text-label-1 text-color-label-3 hover:bg-2-hover rounded-xl disabled:cursor-not-allowed group"
          :disabled="isAccepting"
          @click="acceptSuggestion(result.text, index)"
        >
          <div class="flex items-center gap-2">
            <span class="text-label-3 text-color-label-3">
              {{ result.text }}
              <div class="mt-2 text-caption-1 text-color-label-4 desktop:hidden">
                {{ $t("shared.actions.tap_to_accept") }}
              </div>
            </span>
            <CheckIcon
              v-if="acceptingIndex === index"
              class="w-5 h-5 ml-2 animate-bounce text-semantic-success"
            />
          </div>
        </button>

        <!-- Action buttons for this suggestion -->
        <div class="flex flex-wrap gap-2 mt-2">
          <ActionButtons
            :available-options="availableOptions"
            :is-loading="isLoading"
            @process="text => processText(text, result.text, index)"
          />
        </div>
      </div>

      <!-- Current Streaming Response -->
      <div
        v-if="isLoading"
        ref="streamingRef"
        class="flex flex-col gap-2 p-3 transition-all duration-300 bg-2-static rounded-2xl"
      >
        <div class="flex items-center justify-between gap-2">
          <div class="flex items-center gap-2">
            <p class="text-label-2 text-color-label-4">
              {{ $t("shared.labels.suggestion") }} #{{ suggestionHistory.length + 1 }}
              <span class="text-caption-1 text-color-label-4">({{ currentOperation }})</span>
            </p>
          </div>
        </div>
        <div
          class="p-4 text-left whitespace-pre-line text-label-3 text-color-label-3 rounded-xl"
        >
          {{ currentStreamingText }}
        </div>
      </div>
    </div>
  </BottomDrawerSlot>
</template>

<script setup lang="ts">
import type { TextOperation } from "@/types/text-processing"
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/vue/24/outline"

interface StreamResponse {
  type: "message" | "content" | "update" | "final" | "error"
  data: {
    delta?: string
    fullText?: string
    correctedText?: string
    message?: string
  }
}

interface SuggestionResult {
  text: string
  operation: TextOperation
}

interface Props {
  show: boolean
  originalText: string
  availableOptions: TextOperation[]
  languageCode?: string
}

const props = withDefaults(defineProps<Props>(), {
  show: false,
  languageCode: undefined,
})

const emit = defineEmits<{
  (e: "update:show", value: boolean): void
  (e: "accept", value: string): void
}>()

const containerRef = ref<HTMLElement | null>(null)
const streamingRef = ref<HTMLElement | null>(null)
const lastSuggestionRef = ref<HTMLElement | null>(null)
const isLoading = ref(false)
const suggestionHistory = ref<SuggestionResult[]>([])
const currentStreamingText = ref("")
const currentOperation = ref<TextOperation | null>(null)
const acceptingIndex = ref<number | null>(null)
const isAccepting = ref(false)
const copiedIndex = ref<number | null>(null)

// Watch for streaming text updates to scroll into view
watch(currentStreamingText, () => {
  nextTick(() => {
    if (streamingRef.value) {
      streamingRef.value.scrollIntoView({ behavior: "smooth", block: "end" })
    }
  })
})

function removeResult(index: number) {
  suggestionHistory.value = suggestionHistory.value.filter((_, i) => i !== index)

  // Renumber remaining suggestions
  suggestionHistory.value = suggestionHistory.value.map((result, i) => ({
    ...result,
    number: i + 1,
  }))
}

async function processText(
  operation: TextOperation,
  sourceText: string,
  sourceIndex: number,
) {
  isLoading.value = true
  currentOperation.value = operation
  currentStreamingText.value = ""

  try {
    const response = await api.client.request<Response>(() => ({
      path: "/gpt/process-text",
      method: "POST",
      body: JSON.stringify({
        text: sourceText,
        language_code: props.languageCode ?? t("locale_code"),
        operation,
        options: getOperationOptions(operation),
      }),
    }))

    if (!response?.body) { throw new Error("Text processing failed") }

    const reader = response.body.getReader()
    let finalResult = ""

    while (true) {
      const { done, value } = await reader.read()
      if (done) { break }

      const chunk = new TextDecoder().decode(value)
      const messages = chunk
        .split("\n\n")
        .filter(line => line.startsWith("data: "))
        .map(line => line.slice(6)) // Remove 'data: ' prefix

      for (const message of messages) {
        try {
          if (!message.trim()) { continue }

          const streamResponse = JSON.parse(message)

          switch (streamResponse.type) {
            case "update":
              currentStreamingText.value = streamResponse.data.fullText
              break
            case "final":
              finalResult = streamResponse.data.correctedText.trim()
              currentStreamingText.value = finalResult
              break
            case "error":
              console.error("Stream error:", streamResponse.data.error)
              throw new Error(streamResponse.data.error)
          }
        }
        catch (parseError) {
          console.warn("Failed to parse SSE message:", parseError)
          continue
        }
      }
    }

    if (finalResult) {
      suggestionHistory.value.push({
        text: finalResult,
        operation,
      })

      nextTick(() => {
        if (lastSuggestionRef.value) {
          lastSuggestionRef.value.scrollIntoView({
            behavior: "smooth",
            block: "end",
          })
        }
      })
    }
  }
  catch (error) {
    console.error("Text processing error:", error)
    // TODO: Add toast notification for error
  }
  finally {
    isLoading.value = false
    currentStreamingText.value = ""
    currentOperation.value = null
  }
}

async function acceptSuggestion(text: string, index: number) {
  isAccepting.value = true
  acceptingIndex.value = index

  // Add a small delay to show the animation
  await new Promise(resolve => setTimeout(resolve, 500))

  emit("accept", text)
  onClose()

  // Reset states
  isAccepting.value = false
  acceptingIndex.value = null
}

function onClose() {
  emit("update:show", false)
  suggestionHistory.value = []
  currentStreamingText.value = ""
  currentOperation.value = null
}

function getOperationOptions(operation: TextOperation) {
  switch (operation) {
    case "formal":
      return { formalityLevel: "moderate" }
    case "structure":
      return {
        structureType: `Add basic structure to the text. 
        You are only allowed to use paragraphs, use new lines for headings, and hyphens for bullet points if needed`,
      }
    default:
      return undefined
  }
}

async function copyToClipboard(text: string, index: number) {
  try {
    await navigator.clipboard.writeText(text)
    copiedIndex.value = index

    // Reset the copied state after 2 seconds
    setTimeout(() => {
      if (copiedIndex.value === index) {
        copiedIndex.value = null
      }
    }, 2000)
  }
  catch (err) {
    console.error("Failed to copy text:", err)
    // TODO: Add toast notification for error
  }
}
</script>

<style scoped lang="postcss">
.max-h-[75vh] {
  max-height: 75vh;
  min-height: min(75vh, 600px);
  scroll-padding-bottom: 100px;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-bounce {
  animation: bounce 1s infinite;
}
</style>
