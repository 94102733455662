<template>
  <div>
    <IconOptions class="w-10 h-10 p-2 mr-3" @click="() => (showOptions = true)" />
    <BottomDrawerSlot :show-options="showOptions" @close="() => (showOptions = false)">
      <Button
        :loading="postStore.inProgress"
        type="secondary"
        size="large"
        class="w-full mb-4"
        @click="handleCovertToTemplate"
      >
        <div class="flex items-center">
          <IconTemplate class="mr-1 w-7 text-color-link-static" />
          <p>{{ t("save_as_template") }}</p>
        </div>
      </Button>
      <Button
        type="secondary"
        size="large"
        class="w-full mb-10"
        @click="handleDeletePost"
      >
        <p class="text-color-error">
          {{ t("shared.actions.delete") }}
        </p>
      </Button>
    </BottomDrawerSlot>
  </div>
</template>

<script setup lang="ts">
import { useDeleteStore } from "@/stores/useDeleteStore"

const projectStore = useProjectStore()
const postStore = usePostStore()
const deleteStore = useDeleteStore()
const router = useRouter()
const showOptions = ref<boolean>(false)

async function handleDeletePost() {
  deleteStore.setItemToDelete(postStore.post.id, "posts")
}

watch(
  () => deleteStore.itemDeleted,
  async (newValue) => {
    if (newValue) {
      if (postStore.post.is_template) {
        await postStore.getAllPostTemplates()
        await router.push({ name: "templates" })
      }
      else {
        await projectStore.resyncProject()
        await router.push({
          name: "quote",
          params: { quoteId: postStore.post.phase.project.id },
        })
      }
      showOptions.value = false
      deleteStore.itemDeleted = false
    }
  },
)

async function handleCovertToTemplate() {
  await postStore.convertPostToTemplate(postStore.post)
  showOptions.value = false
}
</script>
