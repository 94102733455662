<template>
  <BaseButton
    :disabled="disabled || loading"
    :variant="type"
    :submit="submit"
    :hide-border="hideBorder"
    :class="[
      rounded,
      {
        'py-[14px] px-7 text-label-bold-1': size === 'large',
        'py-[10px] px-4 text-label-bold-2': size === 'medium',
        'py-[8px] px-2 text-label-bold-2': size === 'small',
      },
    ]"
  >
    <IconAdd v-if="icon === 'plus' && !showSpinner" :class="type" class="mr-1" />
    <AtomSpinner
      v-if="showSpinner"
      class="pr-2"
      :size="15"
      :color="type === 'primary' ? 'white' : 'blue'"
    />
    <MaterialSymbolsCheckSmall v-if="!showSpinner && hasSpinnerBeenShown" />
    <slot />
    <IconChevronDown v-if="icon === 'chevron'" :class="type" class="min-w-[24px]" />
  </BaseButton>
</template>

<script setup lang="ts">
interface Props {
  loading?: boolean
  disabled?: boolean
  size?: "large" | "medium" | "small"
  type?: "primary" | "secondary" | "white" | "danger"
  submit?: boolean
  icon?: "plus" | "chevron"
  hideBorder?: boolean
  rounded?: string
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  disabled: false,
  size: "medium",
  type: "primary",
  submit: false,
  icon: undefined,
  hideBorder: false,
  rounded: "rounded-full",
})

const showSpinner = ref(false)
const hasSpinnerBeenShown = ref(false)

watch(
  () => props.loading,
  () => {
    if (!props.loading) {
      setTimeout(() => {
        showSpinner.value = false
      }, 300)
      setTimeout(() => {
        hasSpinnerBeenShown.value = false
      }, 1000)
    }
    else {
      showSpinner.value = true
      hasSpinnerBeenShown.value = true
    }
  },
  { immediate: true },
)
</script>

<style lang="postcss" scoped>
/* Remove all base button styles as they're now in BaseButton */
/* Only keep print-specific style if needed */
button {
  @apply print:hidden;
}
</style>
