<template>
  <h1 class="mb-4 text-xl font-bold">
    {{ t("auth.login") }}
  </h1>

  <div class="w-9/12 mt-3">
    <Button
      data-testid="mobile-login-btn"
      type="secondary"
      class="w-full mb-4"
      :loading="globalStore.loading"
      @click="mobile"
    >
      <div class="flex items-center justify-center">
        <span>
          <DevicePhoneMobileIcon class="w-6 h-6 mr-5" />
        </span>

        <span>{{ t("auth.mobile") }}</span>
      </div>
    </Button>
    <Button
      data-testid="email-login-btn"
      type="secondary"
      class="w-full"
      size="medium"
      :loading="globalStore.loading"
      @click="email"
    >
      <div class="flex items-center justify-center">
        <span>
          <EnvelopeIcon class="w-6 h-6 mr-5" />
        </span>

        <span>{{ t("email") }}</span>
      </div>
    </Button>

    <div class="mt-3">
      <hr class="my-4">

      <OnboardingButton
        id="create-account"
        class="w-full mb-4"
        type="primary"
        size="medium"
        @click="$router.push({ name: 'onboarding' })"
      >
        {{ $t("onboarding.landing_create_account") }}
      </OnboardingButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useGlobalStore } from "@/stores/globalStore"
import { useProjectStore } from "@/stores/projectStore"
import { DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/vue/24/outline"

const globalStore = useGlobalStore()
const projectStore = useProjectStore()
globalStore.resetState()

const router = useRouter()
async function mobile() {
  globalStore.loginStatus = "phone"
  projectStore.resetState()
  await router.push("/auth/login-mobile")
}
async function email() {
  globalStore.loginStatus = "email"
  projectStore.resetState()
  await router.push("/auth/login-email")
}
</script>
