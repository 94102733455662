<template>
  <div
    class="flex max-h-24 min-h-12 min-w-12 max-w-24 cursor-pointer items-center justify-center overflow-clip rounded-full border-2 hover:opacity-70"
    :style="`border-color: ${borderColor}`"
    @click="updateImage()"
  >
    <AtomSpinner v-if="isLoading" :size="16" />
    <template v-else>
      <img v-if="src" :src="src" class="object-cover">
      <slot v-else />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types"

const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  allowEdit: {
    type: Boolean,
    default: true,
  },

  borderColor: {
    type: String,
    default: "",
  },
  updateImageContex: {
    type: Object as PropType<DirectusContext>,
    default: undefined,
  },
})

const emit = defineEmits<{ imageUpdated: [val: string] }>()

const fileStore = useFileStore()

const isLoading = ref(false)

async function updateImage() {
  if (!props.allowEdit || !props.updateImageContex) {
    return
  }

  const result = await fileStore.retrieveImage()
  if (result) {
    isLoading.value = true
    const fileId = await fileStore.uploadFile(props.updateImageContex as DirectusContext)
    emit("imageUpdated", fileId)
    isLoading.value = false
  }
}
</script>
