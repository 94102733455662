<template>
  <HeaderOnboarding>
    <div class="mb-6 flex flex-col items-center">
      <h2 class="text-heading-2">
        {{ $t("onboarding.provide_code_1") }}
      </h2>
      <p class="mt-2 text-center">
        {{ $t("onboarding.provide_code_2") }}
      </p>
    </div>
    <div>
      <form class="w-full" @submit.prevent="submitForm">
        <TextFieldVal
          :label="t('auth.sms_code')"
          name="verificationCode"
          autocomplete="one-time-code"
          placeholder="0000"
          text-size="normal"
          :bold="false"
          :focus="onboardingStore.status === 'provide-code'"
          class="mb-3"
        />
        <MessageAlert v-if="onboardingStore.error" class="w-full" type="error">
          {{ t("auth.send_sms_code_user_exists") }}
        </MessageAlert>

        <Button
          id="request-new-code"
          type="secondary"
          class="mb-2 w-full"
          size="large"
          @click="goBack"
        >
          {{ $t("onboarding.send_new_code_button") }}
        </Button>
        <Button
          id="submit-code"
          class="w-full"
          size="large"
          :loading="onboardingStore.loading"
          submit
        >
          {{ $t("onboarding.continue_button") }}
        </Button>
      </form>
    </div>
  </HeaderOnboarding>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useOnboardingStore } from "@/stores/onboardingStore"
import { useForm } from "vee-validate"
import * as Yup from "yup"

const onboardingStore = useOnboardingStore()

function goBack() {
  onboardingStore.error = false
  onboardingStore.status = "require-phone"
}

const schema = Yup.object().shape({
  verificationCode: Yup.number().required().typeError("Feil kode. Prøv igjen"),
})
const { handleSubmit } = useForm<Yup.InferType<typeof schema>>({
  validationSchema: schema,
})
const submitForm = handleSubmit(async (form) => {
  await onboardingStore.verifyCode(form.verificationCode)
})
</script>
