<template>
  <div>
    <label v-if="label != ''" class="block mb-1 text-label-2 text-color-label-1">
      {{ label }}
    </label>
    <div class="relative">
      <VueTelInput
        :id="name"
        v-model="fieldValue"
        :name="name"
        :dropdown-options="{
          showDialCodeInSelection: false,
          showFlags: !readonly,
        }"
        :all-countries="allowedCountries"
        mode="international"
        :input-options="{
          placeholder,
          autofocus: focus,
          readonly,
        }"
        style-classes="rounded-lg border border-divider bg-2-static"
        @blur="handleBlur"
      />

      <div ref="textWidthRef" class="absolute invisible whitespace-nowrap">
        {{ fieldValue }}
      </div>
      <div class="absolute bottom-7" :style="{ left: `${textWidth}px` }">
        <SaveState :is-loading="saving" type="symbol" />
      </div>
    </div>
    <p v-if="hasBlured" class="error-message">
      {{ errorMessage }} {{ internalError }}
    </p>
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types"
import { VueTelInput } from "vue-tel-input"
import "vue-tel-input/vue-tel-input.css"

interface Props {
  allowAllCountries?: boolean
  label?: string
  name?: string
  modelValue?: string
  rules?: string | undefined
  placeholder?: string
  directusContext?: DirectusContext
  directusProperty?: string
  focus?: boolean
  readonly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  allowAllCountries: false,
  label: "",
  name: "",
  modelValue: "",
  rules: undefined,
  placeholder: undefined,
  directusContext: undefined,
  directusProperty: undefined,
  focus: false,
  readonly: false,
})

const emit = defineEmits(["update:modelValue", "saving", "blur"])

const {
  value: fieldValue,
  errorMessage,
  meta,
} = useField(props.name, props.rules, {
  initialValue: props.modelValue ? `+${props.modelValue}` : "",
})

const hasBlured = ref(false)
function handleBlur() {
  hasBlured.value = true
  updateFieldOnServer()
  emit("blur") // Add this line to emit the blur event
}

const allowedCountries = props.allowAllCountries
  ? undefined
  : [
      { name: "Norway (Norge)", iso2: "NO", dialCode: "47" },
      { name: "South Africa", iso2: "ZA", dialCode: "27" },
      { name: "Canada", iso2: "CA", dialCode: "1" },
      { name: "France", iso2: "FR", dialCode: "33" },
    ]

const saving = ref(false)
const internalError = ref("")
async function updateFieldOnServer() {
  if (!props.directusContext || !props.directusProperty || !meta.valid) { return }
  const { collection, id } = props.directusContext
  if (!collection || !props.directusProperty || !id) { return }

  saving.value = true
  emit("saving", true)
  const sanatizedMobieNr = sanitizeMobileNumber(fieldValue.value)
  if (!sanatizedMobieNr) {
    saving.value = false
    emit("saving", false)
    internalError.value = t("validations.phone_number")
    return
  }
  else {
    internalError.value = ""
  }
  if (collection === "directus_users" && props.directusProperty) {
    await api.users.updateMe({
      [props.directusProperty]: sanatizedMobieNr,
    })
  }
  else {
    await api.updateGeneric({
      collection,
      id,
      data: { [props.directusProperty]: sanatizedMobieNr },
    })
  }
  emit("saving", false)
  saving.value = false
}

watch(fieldValue, (newValue) => {
  if (meta.valid) {
    const sanatizedMobieNr = sanitizeMobileNumber(newValue)
    emit("update:modelValue", sanatizedMobieNr)
    calculateTextWidth()
  }
  else {
    console.warn("invalid phone number", newValue)
  }
})

const textWidthRef = ref(null) as Ref<HTMLDivElement | null>
const textWidth = ref(0)
function calculateTextWidth() {
  if (textWidthRef.value) {
    textWidth.value = textWidthRef.value.offsetWidth + 110
  }
}
</script>

<style scoped lang="postcss">
.error-message {
  @apply text-label-3;
  @apply font-normal;
  @apply text-error;
  @apply mt-1;
}
</style>
