<template>
  <div class="fixed inset-x-0 bottom-0 pb-safe bg-1-static z-30 shadow-flying">
    <hr class="border-1 border-divider">
    <div class="action-bottom menu">
      <div v-for="(item, i) in menuItems" :key="i">
        <RouterLink
          class="menu-item"
          :class="{ 'text-color-link-static': route.name === item.route.name }"
          :to="item.route"
        >
          <component
            :is="item.icon"
            class="mb-2"
            :active="route.name === item.route.name"
          />
          {{ $t(`menu-mobile.${item.name}`) }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconClient from "@/components/shared/icons/IconClient.vue"
import IconQuote from "@/components/shared/icons/IconQuote.vue"
import IconSettings from "@/components/shared/icons/IconSettings.vue"
import IconStats from "@/components/shared/icons/IconStats.vue"
import IconTemplate from "@/components/shared/icons/IconTemplate.vue"
import { useRoute } from "vue-router"

const route = useRoute()

const menuItems = [
  { name: "offers", route: { name: "quotes" }, icon: IconQuote },
  {
    name: "templates",
    route: { name: "templates" },
    icon: IconTemplate,
  },
  { name: "customers", route: { name: "customers" }, icon: IconClient },
  { name: "stats", route: { name: "stats" }, icon: IconStats },
  { name: "settings", route: { name: "settings" }, icon: IconSettings },
]
</script>

<style lang="postcss">
.menu {
  @apply flex flex-row justify-around;
}
.menu-item {
  @apply flex flex-col items-center;
  @apply text-caption-2 font-semi-bold;
  @apply text-label-2;
  @apply text-caption-2;
}
</style>
