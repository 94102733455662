<template>
  <PageLayout>
    <template #header>
      <p class="text-heading-2">
        {{ t("menu-mobile.settings") }}
      </p>
    </template>
    <template #default>
      <TenantSelector class="mb-4" />
      <p class="pb-2 text-label-bold-1 text-color-label-1">
        {{ t("onboarding.profile_1") }}
      </p>
      <div class="flex items-center p-3 mb-2 rounded-xl bg-1-static">
        <div class="w-12 h-12 mr-2">
          <ProfilePicture />
        </div>

        <LimitedOptions
          class="grow"
          :options="userOptions"
          type="navigation"
          :invert-background="true"
          @selected-id="id => handleUserIdSelection(id)"
        />
      </div>
      <p class="mt-4 mb-2 text-label-bold-1 text-color-label-1">
        {{ t("company-settings.company") }}
      </p>
      <LimitedOptions
        :options="companyOptions"
        type="navigation"
        :invert-background="true"
        @selected-id="id => handleCompanyIdSelection(id)"
      />
      <p class="mt-4 mb-2 text-label-bold-1 text-color-label-1">
        {{ t("company-settings.quote") }}
      </p>
      <Info :text="t('company-settings.quotes.explanation')" />
      <LimitedOptions
        :options="quoteOptions"
        type="navigation"
        :invert-background="true"
        @selected-id="id => handleQuoteIdSelection(id)"
      />

      <LanguageSelector class="my-3" />
      <div v-if="platform === 'web'" class="my-4">
        <ForceUpdate />
      </div>
      <div class="w-full mb-48 text-center text-label-3 text-color-label-4 pb-safe">
        <a
          href="https://tredco.no/brukervilkar/"
          target="_blank"
          class="hover:text-link-hover hover:underline"
        >{{ t("onboarding.landing_terms_and_conditions") }}</a>
        <div>
          <span> {{ t("app_version") }}: {{ versionStore.currentHash }}</span>
        </div>
      </div>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { Capacitor } from "@capacitor/core"
import { useRouter } from "vue-router"

const platform = Capacitor.getPlatform()

const globalStore = useGlobalStore()
const versionStore = useVersionStore()
const router = useRouter()

const showBottomNav = inject<Ref<boolean>>("showBottomNav") || ref(false)
onMounted(() => {
  showBottomNav.value = true
})
onUnmounted(() => {
  showBottomNav.value = false
})

const userOptions = ref([
  {
    label: `${globalStore.user.first_name} ${globalStore.user.last_name}`,
    subLabel: globalStore.user.email,
    id: "user",
    selected: false,
  },
])

const companyOptions = computed(() => {
  const options = [
    {
      label: t("company-settings.account"),
      id: "account",
      selected: false,
      visible: globalStore.requireTheFollowingAccess("account_management").value,
    },
    {
      label: t("company-settings.logo-colors"),
      id: "logo-colors",
      selected: false,
      visible: true,
    },
    {
      label: t("company-settings.employees"),
      id: "employee",
      selected: false,
      visible: globalStore.requireTheFollowingAccess("employee_management").value,
    },
    {
      label: t("company-settings.your_subscription"),
      id: "paywall",
      selected: false,
      visible: true,
    },
  ]
  return options.filter(option => option.visible !== false)
})

const quoteOptions = ref([
  { label: t("company-settings.quotes.settings"), id: "settings", selected: false },
  { label: t("company-settings.quotes.terms"), id: "terms", selected: false },
  { label: t("notes"), id: "notes", selected: false },
  { label: t("quote.settings.payment_plan"), id: "payment-plan", selected: false },
])

function handleUserIdSelection(id: string) {
  if (id === "user") {
    router.push({ name: "profile" })
  }
}

function handleCompanyIdSelection(id: string) {
  if (id === "account") {
    router.push({ name: "general" })
  }
  else if (id === "logo-colors") {
    router.push({ name: "logo" })
  }
  else if (id === "employee") {
    router.push({ name: "employees" })
  }
  else if (id === "paywall") {
    const subsciptionStore = useSubscriptionStore()
    subsciptionStore.goToBillingPortal()
  }
}

function handleQuoteIdSelection(id: string) {
  if (id === "settings") {
    router.push({ name: "invoice" })
  }
  else if (id === "terms") {
    router.push({ name: "settingsTerms" })
  }
  else if (id === "notes") {
    router.push({ name: "settingsNote" })
  }
  else if (id === "payment-plan") {
    router.push({ name: "paymentPlan" })
  }
}
</script>

<style scoped></style>
