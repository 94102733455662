import router from "@/router"
import axios from "axios"
import { defineStore } from "pinia"

interface VersionState {
  currentHash?: string
  newHash?: string
  loading: boolean
  errorStatus: string | null
  environment: string | null
  updateAvailable: boolean
  promtToUpdate: boolean
}

export const useVersionStore = defineStore({
  id: "version",

  state: (): VersionState => ({
    currentHash: "{{ POST_BUILD_ENTERS_HASH_HERE }}",
    newHash: "",
    loading: false,
    errorStatus: null,
    environment: null,
    updateAvailable: false,
    promtToUpdate: false,
  }),
  actions: {
    initVersionCheck(url: string, frequency: number = 1000 * 60 * 2) {
      this.checkVersion(url)
      setInterval(() => {
        this.checkVersion(url)
      }, frequency)
    },
    async checkVersion(url: string) {
      try {
        const fileResponse = await axios.get(`${url}?t=${new Date().getTime()}`)

        // The hash now includes both the hash and version (e.g., "abc123-2.1.3")
        this.newHash = fileResponse.data.hash
        this.environment = fileResponse.data.environment

        this.updateAvailable = this.currentHash !== this.newHash
      }
      catch (error: unknown) {
        this.loading = false
        if (axios.isAxiosError(error)) {
          if (!error.response) {
            this.errorStatus = "Error: Network Error"
          }
          else if (error.response.data) {
            this.errorStatus = error.response.data
          }
        }
      }
    },
    async reloadApp() {
      this.currentHash = this.newHash
      this.updateAvailable = false
      if ("caches" in window) {
        const cacheNames = await window.caches.keys()
        await Promise.all(
          cacheNames.map((cacheName) => {
            return window.caches.delete(cacheName)
          }),
        )
      }
      await router.push("/")
      window.location.reload()
    },
  },
  getters: {
    versionSnippetCurrent: (state) => {
      return state.currentHash
    },
    versionSnippetNew: (state) => {
      if (!state.newHash || state.newHash === "") {
        return ""
      }
      return state.newHash
    },
  },
})
