<template>
  <div class="fixed inset-x-0 bottom-0 pb-safe bg-1-static z-30 border shadow-2xl">
    <div class="action-bottom p-3">
      <slot />
    </div>
  </div>
  <div class="pb-3" />
</template>

<script setup lang="ts"></script>
