<template>
  <div class="rounded-[20px] border border-button-secondary p-1">
    <div class="flex flex-wrap justify-around rounded-[16px] bg-2-static shadow-inset">
      <div
        :class="[
          {
            'bg-button-primary-static text-label-bold-1 text-white': side === 'left',
            'text-color-label-1 hover:bg-2-hover': side === 'right',
          },
        ]"
        class="m-1 grow rounded-[12px] px-4 py-2 text-center text-label-bold-2"
        @click="toggleLeftRight"
      >
        {{ valueLeft }}
      </div>
      <div
        :class="[
          {
            'bg-button-primary-static text-label-bold-1 text-white': side === 'right',
            'text-color-label-1 hover:bg-2-hover': side === 'left',
          },
        ]"
        class="m-1 grow truncate rounded-[12px] px-4 py-2 text-center text-label-bold-2"
        @click="toggleLeftRight"
      >
        {{ valueRight }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  valueLeft: string
  valueRight: string
  default?: "left" | "right"
}
const props = withDefaults(defineProps<Props>(), {
  default: "right",
})
const emit = defineEmits(["left-click", "right-click"])
const side = ref(props.default)
function toggleLeftRight() {
  if (side.value === "left") {
    side.value = "right"
    emit("right-click")
  }
  else {
    side.value = "left"
    emit("left-click")
  }
}
</script>
