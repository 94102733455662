<template>
  <div
    class="relative w-full gap-12 p-4 bg-center bg-no-repeat bg-cover shadow-sm rounded-2xl bg-welcome-gradient text-color-button-primary"
  >
    <!-- <div v-if="!globalStore.user.developer">
      <IconButton class="absolute right-3 top-1">
        <IconExit class="rounded-full hover:bg-link-static !text-white" />
      </IconButton>
      <h1 class="mb-6 text-heading-3">
        {{ $t("welcome.welcome_title") }} {{ globalStore.user.first_name }}!
      </h1>
      <div class="text-label-2">
        {{ $t("welcome.first_quote") }}
      </div>
    </div> -->
    <SubscriptionsWidget  />
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/globalStore"

const globalStore = useGlobalStore()

const showInfoBox = ref(true)

// function hideInfoBox() {
//   localStorage.setItem("showInfoBox", "false")
//   showInfoBox.value = false
// }

onMounted(() => {
  const show = ref(localStorage.getItem("showInfoBox") !== "false")
  showInfoBox.value = show.value
})
</script>
