// utility.ts
import type { Phase, Project } from "@/types"

interface InputJson {
  summary: {
    user_email: string
    selected_tiles: {
      product: Array<{
        text: string
        article_no: string
        quantity: string
        number_of_packages: string
        products_per_package: string
        total_m2: number
        used_m2: number
      }>
    }
    selected_vinyl: string
    selected_furniture: {
      product: Array<{
        articles: {
          article:
            | Array<{
              text: string
              article_no: string
              quantity: number
            }>
            | {
              text: string
              article_no: string
              quantity: number
            }
        }
      }>
    }
    project_info: {
      name: string
      proposal_info: {
        name: string
        properties: string
      }
      properties: string
    }
  }
}

export default function convertJsonToProject(input: InputJson): Partial<Project> {
  const { summary } = input
  const project: Partial<Project> = {
    name: summary.project_info.name,
    contact_person_email: summary.user_email,
    phases: [],
  }

  const tilePhase: Phase = {
    id: "",
    status: "",
    name: "Tiles",
    posts: summary.selected_tiles.product.map(tile => ({
      name: tile.text,
      description: `Article No: ${tile.article_no}, Total M2: ${tile.total_m2}, Used M2: ${tile.used_m2}`,
      unit_price: 100, // Assuming price is to be set elsewhere
      qty: Number(tile.quantity),
      unit: "m2",
    })),
  }

  const furniturePhase: Phase = {
    id: "",
    status: "",
    name: "Furniture",
    posts: summary.selected_furniture.product.flatMap((furniture) => {
      const articles = Array.isArray(furniture.articles.article)
        ? furniture.articles.article
        : [furniture.articles.article]

      return articles.map(article => ({
        name: article.text,
        description: `Article No: ${article.article_no}`,
        unit_price: 0, // Assuming price is to be set elsewhere
        qty: article.quantity,
        unit: "pcs",
      }))
    }),
  }

  project.phases?.push(tilePhase, furniturePhase)

  return project
}

export function cleanJsonObject(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(cleanJsonObject)
  }
  else if (obj && typeof obj === "object") {
    if (typeof obj._text === "string") {
      return obj._text
    }
    const newObj: any = {}
    for (const key in obj) {
      newObj[key] = cleanJsonObject(obj[key])
    }
    return newObj
  }
  else {
    return obj
  }
}
