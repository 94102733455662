<template>
  <div class="grid-cols-5 desktop:grid">
    <div class="col-span-3 desktop:border-r desktop:pr-4">
      <Quote v-if="!toggleQuotePreview" />
      <QuotePreview v-if="toggleQuotePreview" />
    </div>
    <div
      v-if="!mobile && projectStore.project"
      class="col-span-2 mx-4 mb-5 mt-5 rounded-2xl"
    >
      <div class="mb-4 flex items-center">
        <p class="mr-4 grow text-heading-3 text-color-label-1">
          {{ $t("quote.settings.configure_quote") }}
        </p>
        <QuoteStatus
          :current-status="projectStore.project.quote_status"
          @change-status="updateQuoteStatus"
        />
      </div>
      <div class="my-4">
        <Toggle
          :value-left="$t('edit')"
          :value-right="$t('preview')"
          default="left"
          @left-click="toggleQuotePreview = false"
          @right-click="toggleQuotePreview = true"
        />
      </div>
      <QuoteSettings />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { QuoteStatuses } from "@/types"

const projectStore = useProjectStore()
const toggleQuotePreview = ref(false)
async function updateQuoteStatus(status: QuoteStatuses) {
  projectStore.project.quote_status = status
  await projectStore.updateProject()
}

const mobile = inject("isPhone") as Ref<boolean>
window.scrollTo(0, 0)
</script>
