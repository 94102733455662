<template>
  <div class="p-4 bg-1-static desktop:m-4 desktop:rounded-2xl">
    <Post />
  </div>
  <div v-if="!postStore.post.is_template" class="w-full p-4">
    <Button
      icon="plus"
      type="secondary"
      class="w-full mt-4 mb-3"
      size="large"
      :loading="projectStore.creatingPost"
      @click="newPost"
    >
      {{ $t("quote.save_and_new_post") }}
    </Button>
    <Button class="w-full" size="large" type="primary" @click="postStore.goBack">
      {{
        $t("shared.actions.save")
      }}
    </Button>
  </div>
</template>

<script setup lang="ts">
import { usePostStore } from "@/stores/postStore"
import { useProjectStore } from "@/stores/projectStore"
import { useRoute, useRouter } from "vue-router"

const postStore = usePostStore()
const projectStore = useProjectStore()
const router = useRouter()
const route = useRoute()

const context = ref<"quote-view" | "stand-alone-view">("stand-alone-view")
const showOptions = ref(false)
const postId = route.params.postId as string

provide<boolean>("editing", true)
provide<Ref<boolean>>("showOptions", showOptions)
provide("context", context)

await confirmThatPostIsLoaded()

async function confirmThatPostIsLoaded() {
  if (postStore.post.name === "...") {
    await postStore.loadPost(postId)
  }
}

async function newPost() {
  await projectStore.setProject(postStore.post.phase?.project.id)
  const post = await postStore.newPost()

  postStore.loadPost(post.id)
  await router.push({
    name: "post",
    params: { postId: post.id },
  })
}
</script>
