<template>
  <div class="bottom-0 mb-6 h-full w-full rounded-t-3xl bg-1-static px-6 ">
    <div class="flex items-center py-3">
      <IconArrowBackward class="pl-4" @click="goBack" />
      <p class="mx-auto text-label-2 text-color-label-3">
        {{ $t("onboarding.step", [index, StatusValues.length]) }}
      </p>
    </div>
    <hr class="border-1 mb-2 border-divider">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { StatusValues, useOnboardingStore } from "@/stores/onboardingStore"

const onboardingStore = useOnboardingStore()
const status = onboardingStore.status
const index = StatusValues.indexOf(status)

function goBack() {
  onboardingStore.status = StatusValues[index - 1]
}
</script>
