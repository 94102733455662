<template>
  <div :class="$attrs.class" class="relative mt-1 h-fit min-h-[42px]">
    <div>
      <div
        class="relative"
        @click="() => (optionsOpen = options.length > 0 || value.length >= 3)"
      >
        <TextFieldVal
          ref="input"
          v-model="value"
          :placeholder="placeholder"
          :label="name"
          type="text"
          :name="name"
          @focus="optionsOpen = options.length > 0 || value.length >= 3"
        />

        <IconSearch :size="28" class="absolute bottom-2 right-2 flex items-center pr-2" />
      </div>
    </div>
    <ul
      v-if="optionsOpen || isPending"
      ref="optionsPanelEl"
      class="absolute z-10 mt-1 max-h-[250px] w-full overflow-x-visible overflow-y-scroll rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      tabindex="-1"
    >
      <li
        v-if="isPending || options.length == 0"
        class="relative cursor-default select-none overflow-x-clip py-2 pl-3 pr-4 text-left"
      >
        <span v-if="isPending">{{ $t("loading") }}...</span>
        <span v-else>{{ $t("no_result") }}</span>
      </li>
      <li
        v-for="(option, i) in options"
        :key="option.value"
        class="relative cursor-default select-none overflow-x-clip py-2 pl-3 pr-4 text-left hover:bg-gray-100"
        :class="{ 'border-t': i > 0 }"
        @click="handleSelect(option)"
      >
        <slot name="option" :option="option">
          <span>{{ option }}</span>
        </slot>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, useVModel, watchPausable } from "@vueuse/core"
import _ from "lodash"

interface Props {
  options?: Array<unknown>
  placeholder?: string
  name?: string
  error?: boolean
  modelValue?: string
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  placeholder: "",
  name: "",
  error: false,
  modelValue: "",
})
const emit = defineEmits(["update:modelValue", "select", "search"])

const options = toRef(props, "options")

watch(options, () => {
  if (options.value.length > 0 || value.value.length >= 3) {
    optionsOpen.value = true
  }
})

const value = useVModel(props, "modelValue", emit)

const optionsOpen = ref(false)
const optionsPanelEl = ref(null)
const isPending = ref<boolean>(false)
onClickOutside(optionsPanelEl, () => (optionsOpen.value = false))

const search = _.debounce(() => {
  options.value.length = 0

  if (value.value.length >= 3) {
    isPending.value = true
    emit("search", value.value, isPending)
  }
  else {
    optionsOpen.value = false
    isPending.value = false
  }
}, 1000)

const { pause, resume } = watchPausable(value, (newSearch: string, oldSearch: string) => {
  if (newSearch === oldSearch) { return }
  search()
})

const handleSelect = async function (option) {
  pause()
  emit("select", option)
  optionsOpen.value = false
  await nextTick()
  resume()
}

const input = ref<HTMLInputElement>()

defineExpose({ input })
</script>
