<template>
  <div>
    <IconOptions class="w-10 h-10 p-2 mr-3" @click="() => (showOptions = true)" />
    <BottomDrawerSlot :show-options="showOptions" @close="() => (showOptions = false)">
      <Button
        type="secondary"
        size="large"
        class="w-full mb-10"
        @click="attemptDeleteCustomer(id)"
      >
        <div class="text-color-error">
          {{ t("shared.actions.delete") }}
        </div>
      </Button>
      <MobileDialog
        :sub-title="t('customers.cannot_delete_customer')"
        type="info"
        :show="showCannotDeleteInfo"
        @close="showCannotDeleteInfo = false"
      >
        <hr class="my-3">
        <div v-for="p of customer?.projects" :key="p.name">
          <p class="text-label-2 text-color-link-static">
            {{ p.name }}
          </p>
        </div>
        <hr class="my-3">
      </MobileDialog>
    </BottomDrawerSlot>
  </div>
</template>

<script setup lang="ts">
import type { Customer } from "@/types"

import api from "@/api"
import { t } from "@/localization/i18n"
import { useDeleteStore } from "@/stores/useDeleteStore"

interface Props {
  id: string
}

const props = defineProps<Props>()
const showCannotDeleteInfo = ref(false)
const router = useRouter()
const showOptions = ref(false)

const customer = ref<Customer | undefined>(undefined)
const deleteStore = useDeleteStore()

async function attemptDeleteCustomer(id: string) {
  customer.value = await api.customers.getCustomer(id)
  if (customer.value?.projects?.length) {
    showCannotDeleteInfo.value = true
  }
  else {
    deleteStore.setItemToDelete(id, "customers")
  }
}

watch(
  () => deleteStore.itemDeleted,
  async (newValue) => {
    if (newValue) {
      await router.push({ name: "customers" })
      deleteStore.itemDeleted = false
    }
  },
)
</script>
