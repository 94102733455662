import type { Post, PostCreate } from "@/types"
import api from "@/api"
import { t } from "@/localization/i18n"
import { toast } from "@/plugins/toasterPlugin"
import router from "@/router"
import { useGlobalStore } from "@/stores/globalStore"
import { useProjectStore } from "@/stores/projectStore"
import initials from "@/types/initialValues"
import { defineStore } from "pinia"

interface PostState {
  posts: Post[]
  postTemplates: Post[]
  post: Post
  inProgress: boolean
  creatingPostTemplate: boolean
  globalStore: ReturnType<typeof useGlobalStore>
  projectStore: ReturnType<typeof useProjectStore>
  addTemplateToCurrentProject: boolean
  status: "new" | "edit"
}

export const usePostStore = defineStore("posts", {
  state: (): PostState => ({
    posts: [initials.defaultPostsValue],
    postTemplates: [initials.defaultPostsValue],
    post: initials.defaultPostsValue,
    creatingPostTemplate: false,
    globalStore: useGlobalStore(),
    projectStore: useProjectStore(),
    inProgress: false,
    addTemplateToCurrentProject: false,
    status: "edit",
  }),
  actions: {
    async hydrate() {
      this.projectStore.setProject(this.projectStore.project?.id ?? "")
    },
    async getAllPostTemplates() {
      const tenantId = this.globalStore.tenant?.id
      if (!tenantId) {
        throw new Error("No tenant id")
      }
      const posts = await api.quotes.getPosts(tenantId, true)

      if (posts.length !== 0) {
        this.postTemplates = posts
      }
      else {
        this.postTemplates = [initials.defaultPostsValue]
      }
      this.sortPosts()
    },
    async updateActivePost() {
      await this.updatePost(this.post.id, {
        status: this.post.status,
        name: this.post.name,
        qty: this.post.qty,
        unit: this.post.unit,
        unit_price: this.post.unit_price,
        description: this.post.description,
      })
    },
    async updatePost(
      id: string,
      values: {
        name?: string
        qty?: string
        unit_price?: string
        unit?: string
        description?: string
        sort?: number
        pictures?: {
          directus_files_id: string
        }[]
      },
    ) {
      this.inProgress = true
      await api.quotes.updatePost(id, values)
      if (this.projectStore.project?.id) {
        this.projectStore.resyncProject(this.projectStore.project?.id)
      }
      else if (this.post.is_template) {
        await this.getAllPostTemplates()
      }
      this.inProgress = false
    },
    async updateSortingOnPost(id: string, direction: "up" | "down") {
      const postIndex = this.posts.findIndex(post => post.id === id)
      if (postIndex === -1) {
        throw new Error("Post not found")
      }

      if (direction === "up" && postIndex > 0) {
        const temp = this.posts[postIndex]
        this.posts[postIndex] = this.posts[postIndex - 1]
        this.posts[postIndex - 1] = temp
      }
      else if (direction === "down" && postIndex < this.posts.length - 1) {
        const temp = this.posts[postIndex]
        this.posts[postIndex] = this.posts[postIndex + 1]
        this.posts[postIndex + 1] = temp
      }

      this.posts.map((post) => {
        post.sort = this.posts.findIndex(p => p.id === post.id) + 1
        api.quotes.updatePost(post.id, { sort: post.sort })
      })
    },
    async newPost(values?: Omit<PostCreate, "tenant" | "phase">) {
      this.inProgress = true
      let phaseId = this.projectStore.project?.phases?.[0]?.id
      const tenantId = this.globalStore.tenant?.id
      const projectId = this.projectStore.project?.id
      if (!phaseId && this.projectStore.project?.id && tenantId) {
        const newPhase = await api.quotes.newPhase({
          name: "Fase 1",
          project: this.projectStore.project.id,
          tenant: tenantId,
        })

        await this.projectStore.resyncProject(this.projectStore.project.id)
        phaseId = newPhase.id
      }

      if (!tenantId || !phaseId || !projectId) {
        this.inProgress = false
        throw new Error("Tenant, phase or project is not set")
      }

      if (values === undefined) {
        values = {
          name: t("quote.new_post"),
          description: "",
          qty: "1",
          unit: "pcs",
          unit_price: "0",
          sort: this.posts.length + 1,
        }
      }

      const newPost = await api.quotes.newPost({
        ...values,
        phase: phaseId,
        tenant: tenantId,
        sort: this.posts.length + 1,
      })
      const newUpdatedPost = await this.loadPost(newPost.id)

      await this.projectStore.resyncProject(projectId)
      this.inProgress = false
      return newUpdatedPost
    },
    async convertTemplateToPost(post: Post) {
      const postFromTemplate = await this.newPost({
        name: post.name,
        qty: post.qty,
        unit: post.unit,
        description: post.description,
        unit_price: post.unit_price,
        is_template: false,
        template_reference: post.id,
      })
      return postFromTemplate
    },
    async convertPostToTemplate(post: Post) {
      this.inProgress = true
      const postFromTemplate = await api.quotes.newPostTemplate({
        name: post.name,
        qty: post.qty,
        unit: post.unit,
        description: post.description,
        unit_price: post.unit_price,
        is_template: true,
        tenant: post.tenant,
      })
      this.inProgress = false
      toast.success(t("templates.post_added_as_template_message"))
      return postFromTemplate
    },
    async createPostTemplate() {
      this.creatingPostTemplate = true
      const tenantId = this.globalStore.tenant?.id
      if (!tenantId) {
        this.creatingPostTemplate = false
        throw new Error("No tenant id")
      }
      const newPostTemplate = await api.quotes.newPost({
        name: t("quote.new_post_template"),
        qty: "0",
        unit: "pcs",
        unit_price: "0",
        phase: null,
        tenant: tenantId,
        is_template: true,
        description: "",
      })
      await this.getAllPostTemplates()
      await this.loadPost(newPostTemplate.id)
      this.sortPosts()
      this.creatingPostTemplate = false
      return newPostTemplate
    },

    sortPosts() {
      if (this.posts.length > 1 && this.posts[0].sort === null) {
        this.posts.sort((a: Partial<Post>, b: Partial<Post>) => {
          const dateA = a.date_created ? new Date(a.date_created) : new Date(0)
          const dateB = b.date_created ? new Date(b.date_created) : new Date(0)
          return dateA.getTime() - dateB.getTime()
        })
      }
      else {
        this.posts.sort((a: Post, b: Post) => {
          return a.sort - b.sort
        })
      }
    },
    async loadPost(id: string, force = false) {
      this.inProgress = true
      try {
        let post: Post | undefined
        post = this.posts.find(post => post.id === id)
        if (!post || force) {
          post = await api.quotes.getPost(id)
          if (post) {
            const index = this.posts.findIndex(p => p.id === post.id)
            if (index !== -1) {
              this.posts[index] = post
            }
            else {
              this.posts.push(post)
            }
          }
        }
        this.post = post
        this.inProgress = false
        return post
      }
      catch (error) {
        console.error("Failed to load post: ", error)
        throw error
      }
    },
    async goBack() {
      await this.updateActivePost()

      if (this.addTemplateToCurrentProject) {
        const newPost = await this.convertTemplateToPost(this.post)
        this.post = newPost
        this.addTemplateToCurrentProject = false
      }

      if (this.post.is_template) {
        await router.push({ name: "templates" })
      }
      else {
        await router.push({
          name: "quote",
          params: { quoteId: this.post.phase.project.id },
        })
      }
    },
    async deleteAllPosts() {
      this.posts.map(async (post) => {
        await api.updateGeneric({
          collection: "posts",
          id: post.id,
          data: { status: "archived" },
        })
      })
      setTimeout(async () => {
        await this.projectStore.resyncProject(this.projectStore.project?.id ?? "")
      }, 1500)
    },
    resetState() {
      this.posts = [initials.defaultPostsValue]
    },
  },
})
