import type { QuoteStatuses } from "@/types"
import { useDataStore } from "@/stores/dataStore"
import { toValue } from "@vueuse/core"
import { defineStore } from "pinia"
import { computed, ref } from "vue"

export const useProjectsView = defineStore("projectsView", () => {
  const dataStore = useDataStore()
  const projectController = useProjectController()

  const searchTerm = ref("")
  const filterStatus = ref<QuoteStatuses[]>([])

  async function updateFilter(statuses: QuoteStatuses[]) {
    filterStatus.value = statuses
    await projectController.updateProjectFilter(statuses)
  }

  const filteredProjects = computed(() => {
    let filtered = dataStore.projects

    if (filterStatus.value.length) {
      filtered = filtered.filter(p => filterStatus.value.includes(p.quote_status))
    }

    if (searchTerm.value) {
      const query = searchTerm.value.toLowerCase().trim()
      filtered = filtered.filter(
        p =>
          p.name?.toLowerCase().includes(query)
          || toValue(p.$customer)?.name?.toLowerCase().includes(query)
          || toValue(p.$customer)?.city?.toLowerCase().includes(query),
      )
    }

    return filtered
  })

  function setSearchTerm(term: string) {
    searchTerm.value = term
  }

  return {
    searchTerm,
    filterStatus,
    filteredProjects,
    setSearchTerm,
    dataStore,
    updateFilter,
  }
})
