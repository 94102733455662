<template>
  <div v-if="props.fileIds.length !== 0">
    <ul class="flex" :class="[{ 'flex-wrap': props.wrapPhotos }]">
      <li
        v-for="id in props.fileIds"
        :key="id"
        class="mx-1 h-16 w-16 min-w-[64px] overflow-hidden rounded-xl bg-black"
        :class="[{ 'my-1': props.wrapPhotos }]"
      >
        <img
          :src="imageUrls[id]"
          alt="Thumbnail"
          class="h-full w-full object-cover"
          @click="goToImage(id)"
        >
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useImageStore } from "@/stores/imageStore"
import { fileUrl } from "@/utils/fileUrl"

const props = withDefaults(defineProps<Props>(), {
  wrapPhotos: false,
})
const globalStore = useGlobalStore()
const imageStore = useImageStore()

interface Props {
  fileIds: string[]
  wrapPhotos?: boolean
}
async function goToImage(id: string) {
  imageStore.status = "edit"
  imageStore.imageId = id

  imageStore.hydrateStoreWithRemoteImage(await fileUrl(id, globalStore.token))
}

const imageUrls = ref<{ [id: string]: string }>({})
watch(
  () => props.fileIds,
  async (newIds) => {
    for (const id of newIds) {
      imageUrls.value[id] = await fileUrl(id, globalStore.token, true)
    }
  },
  { immediate: true },
)
</script>
