import type { Post, Project, QuoteStatuses } from "@/types"
import api from "@/api"
import { useGlobalStore } from "@/stores/globalStore"
import { usePostStore } from "@/stores/postStore"
import { calculateTotalIncVat, calculateVat } from "@/utils/calculateGrandTotal"
import { defineStore } from "pinia"

interface ProjectState {
  projects: Project[]
  project: Project | null
  filterStatus: QuoteStatuses[]
  index: number
  loading: boolean
  creatingPost: boolean
  globalStore: ReturnType<typeof useGlobalStore>
  status:
    | "idle"
    | "new-start"
    | "new-pick-customer"
    | "edit"
    | "edit-pick-customer"
    | "edit-pick-from-template"
  pdfUrl: string
}

export const useProjectStore = defineStore("project", {
  state: (): ProjectState => ({
    projects: [],
    project: null,
    filterStatus: [],
    index: 0,
    loading: false,
    creatingPost: false,
    globalStore: useGlobalStore(),
    status: "idle",
    pdfUrl: "",
  }),
  actions: {
    async resyncProject(id?: string) {
      if (!id) { id = this.project?.id }
      if (!id) { return }
      const project = await api.quotes.getProject(id)
      if (project) {
        const existing = this.projects.find(p => p.id === id)
        if (existing) {
          Object.assign(existing, project)
        }
        else {
          this.projects.push(project)
          this.sortProjects()
        }
      }
      await this.setProject(id)
    },
    async setProject(
      id: string,
      projectFromSb: Partial<Project> | undefined = undefined,
    ) {
      this.project = this.projects.find(p => p.id === id)

      if (!this.project) {
        this.project = await api.quotes.getProject(id)
        this.projects.push(this.project)
      }
      if (projectFromSb) {
        this.project = { ...this.project, ...projectFromSb }
      }

      let posts: Post[] = []
      // check if both project and phases are not null
      if (this.project && this.project.phases && this.project.phases?.length > 0) {
        posts = this.project.phases[0].posts as Post[]
      }
      const postStore = usePostStore()
      postStore.posts = posts
      postStore.sortPosts()
      this.status = "edit"
    },
    async startNewProject() {
      this.loading = true
      this.status = "new-start"

      const context = {
        user: this.globalStore.user,
        tenant: this.globalStore.tenant,
        employee: this.globalStore.employee,
      }

      this.project = (await scaffoldNewProject(context)) as Project
      this.loading = false
    },
    async saveProject() {
      this.loading = true
      if (!this.project) {
        return console.warn("No project to save")
      }
      if (!this.project.customer) {
        console.log("Customer not set, project will not be saved")
      }
      const project = await api.quotes.newProject(this.project)
      await this.setProject(project.id)
      this.loading = false
      this.status = "edit"
      return project
    },
    async duplicate(id: string, foreignTenantId?: string) {
      await this.setProject(id)
      const originalProject = this.project
      if (!originalProject) {
        console.log("Project not found")
        return
      }

      this.loading = true
      const tenantId = foreignTenantId || this.globalStore.tenant?.id

      if (!tenantId) {
        throw new Error("No tenant ID available")
      }

      const newProject = await duplicateProject(originalProject, tenantId)
      const createdProject = await api.quotes.newProject(newProject)

      if (!foreignTenantId) {
        this.projects.push(createdProject)
        this.sortProjects()
        await this.setProject(createdProject.id)
      }

      this.loading = false
      return createdProject
    },
    sortProjects() {
      this.projects.sort((a: Partial<Project>, b: Partial<Project>) => {
        const dateA = a.date_created ? new Date(a.date_created) : new Date(0)
        const dateB = b.date_created ? new Date(b.date_created) : new Date(0)
        return dateB.getTime() - dateA.getTime()
      })
    },
    async updateProject() {
      if (!this.project) {
        throw new Error("No project has been set in the project store")
      }
      const projectId = this.project.id

      if (!this.project.customer.id) { throw new Error("Customer not set") }

      this.loading = true
      const project = await api.quotes.updateProject(projectId, {
        name: this.project.name ?? "",
        customer: this.project.customer?.id,
        terms: this.project.terms,
        discount_amount: this.project.discount_amount,
        quote_status: this.project.quote_status,
        post_image_size: this.project.post_image_size,
        note: this.project.note,
        payment_plan: this.project.payment_plan,
        picture: this.project.picture,
      })

      if (this.project?.id === project.id) {
        Object.assign(this.project, project)
      }

      this.loading = false
    },
    resetState() {
      this.projects = []
      this.project = null
      this.loading = false
      this.creatingPost = false
      this.status = "idle"
    },
  },
  getters: {
    grandTotal(state): number {
      return calculateGrandTotal(state.project)
    },
    vat(state): number {
      return calculateVat(state.project, this.globalStore.tenant)
    },
    totalIncVat(state): number {
      return calculateTotalIncVat(state.project, this.globalStore.tenant)
    },
    quoteLabelName: (state) => {
      if (!state.project || !state.project.customer) {
        return "Velg kunde"
      }
      else {
        return state.project.customer.name
      }
    },
    directusContext: (state) => {
      return {
        collection: "projects",
        property: "",
        id: state.project?.id,
      }
    },
    projectIsInAValidState: (state) => {
      if (!state.project) { return false }
      const projectHasName = state.project.name !== ""
      const projectHasCustomer = state.project.customer !== undefined
      return projectHasName && projectHasCustomer
    },
  },
})
