import type { PDFEmailData } from "@/pdf-generator/types"
import type { Phase, Post, PostCreate, PostQuery, Project, Schema, Tenant } from "@/types"
import type { QueryFields } from "@directus/sdk"
import { client, withRetry } from "@/api/directus"
import { getSecondaryColor } from "@/composables/color"
import { config } from "@/config"
import { createItem, readItem, readItems, updateItem } from "@directus/sdk"
import axios from "axios"
import { ref } from "vue"

const oldPostFields = [
  "id",
  "name",
  "status",
  "unit",
  "description",
  "qty",
  "unit_price",
  "pictures.directus_files_id.id",
  "pictures.id",
  "phase.project.id",
  "date_created",
  "tenant",
  "activities.*",
  "products.*",
  "is_template",
]

const postFields = [
  "id",
  "name",
  "sort",
  "status",
  "unit",
  "description",
  "qty",
  "unit_price",
  { pictures: [{ directus_files_id: ["id"] }, "id"] },
  { phase: [{ project: ["id"] }] },
  "date_created",
  "tenant",
  "activities.*",
  "products.*",
  "is_template",
]

const projectFields = [
  "*",
  { user_created: ["*"] },
  { customer: ["*"] },
  { phases: ["*", { posts: postFields }] },
] as QueryFields<Schema, Project>

async function getProject(id: string): Promise<Project> {
  return await withRetry(
    async () => {
      const project = (await client.request(
        readItem("projects", id, { fields: projectFields }),
      )) as Project
      return project
    },
    3,
    false,
  )
}

async function getHighestQuoteNumber(): Promise<number> {
  return await withRetry(
    async () => {
      const project = (await client.request(
        readItems("projects", {
          fields: ["quote_number"],
          sort: ["-quote_number"],
          limit: 1,
        }),
      )) as Project[]

      if (project.length === 0) {
        return 10001
      }
      return project[0].quote_number
    },
    3,
    false,
  )
}

async function newProject(values: Partial<Project>): Promise<Project> {
  return await withRetry(
    async () => {
      const project = (await client.request(
        createItem("projects", values, {
          fields: projectFields,
        }),
      )) as Project
      return project
    },
    3,
    true,
  )
}

export async function updateProject(
  id: string,
  values: {
    name: string
    customer: string
    [key: string]: any
  },
): Promise<Project> {
  return await withRetry(
    async () => {
      const project = (await client.request(
        updateItem("projects", id, values, {
          fields: projectFields,
        }),
      )) as Project
      return project
    },
    3,
    true,
  )
}

async function getPosts(
  tenantId: string,
  isTemplate = false,
  search = "",
): Promise<Post[]> {
  return await withRetry(
    async () => {
      const query: PostQuery = {
        fields: oldPostFields,
        filter: {
          status: { _eq: "draft" },
          is_template: { _eq: isTemplate },
          tenant: {
            _eq: tenantId,
          },
        },
        limit: -1,
        sort: ["date_created"],
      }

      if (search !== "") {
        query.search = search
      }
      const posts = (await client.request(readItems("posts", query))) as Post[]
      return posts
    },
    3,
    false,
  )
}

async function getPost(id: string): Promise<Post> {
  return await withRetry(
    async () => {
      return (await client.request(
        readItem("posts", id, { fields: postFields }),
      )) as unknown as Post
    },
    3,
    false,
  )
}

async function updatePost(
  id: string,
  values: {
    name?: string
    qty?: string
    unit_price?: string
    unit?: string
    description?: string
    status?: string
    sort?: number
  },
) {
  return await withRetry(
    async () => {
      await client.request(updateItem("posts", id, values, { fields: postFields }))
    },
    3,
    true,
  )
}

async function newPost(values: Partial<Post>): Promise<Post> {
  return await withRetry(
    async () => {
      return (await client.request(
        createItem("posts", values, { fields: postFields }),
      )) as unknown as Post
    },
    3,
    true,
  )
}

async function newPostTemplate(
  values: Omit<PostCreate, "phase" | "template_reference">,
): Promise<Post> {
  return await withRetry(
    async () => {
      values.is_template = true
      const post = (await client.request(createItem("posts", values))) as Post
      return getPost(post.id)
    },
    3,
    true,
  )
}

async function newPhase(values: {
  name: string
  project: string
  tenant: string
}): Promise<Phase> {
  return await withRetry(
    async () => {
      const phase = (await client.request(createItem("phases", values))) as Phase
      return phase
    },
    3,
    true,
  )
}

async function updatePhase(
  id: string,
  values: {
    name?: string
    status?: string
  },
): Promise<Phase> {
  return await withRetry(
    async () => {
      const phase = (await client.request(updateItem("phases", id, values))) as Phase
      return phase
    },
    3,
    true,
  )
}

async function linkFileAndPost(values: {
  posts_id: string
  directus_files_id: string
  tenant: string
}) {
  await client.request(createItem("posts_files", values))
}

async function downloadPdf(
  project: Project,
  tenant: Tenant,
  userId: string,
): Promise<string> {
  const colors = ref({ primary: "#000000", secondary: "#000000", customized: false })
  colors.value.primary = tenant.invoice_color ?? "#000000"
  colors.value.secondary = getSecondaryColor(colors.value.primary)
  const directusData = JSON.parse(localStorage.getItem("directus-data") ?? "{}")
  const language = localStorage.getItem("tredco-app-language") ?? "en"
  await client.refresh()
  try {
    if (config.generatePdfLocally === "web") {
      const pdfGeneratorPath = `?project_id=${project.id}&access_token=${directusData.access_token}&user_id=${userId}&language=${language}`
      const localPdfUrl = `${config.generatePdfUrl}${pdfGeneratorPath}`
      return localPdfUrl
    }
    else {
      const response = await axios.post(`${config.generatePdfUrl}`, {
        "name": project.name,
        "project": project.id,
        "tenant": tenant.id,
        language,
        "access_token": directusData.access_token,
        "user_id": userId,
        /* "footer-text": "Tredco - text to be inserted", */
        "primary-color": colors.value.primary,
        "secondary-color": colors.value.secondary,
      })

      if (!config.isProduction && config.generatePdfLocally === "function") {
        alert(
          `It is not possible to open the file via the browser, it has to be accessed directly on disk. ${response.data.url}`,
        )
      }

      return response.data.url ?? ""
    }
  }
  catch (error) {
    console.error(error)
  }
  return ""
}

async function sendQuoteEmail(
  projectId: string,
  tenant: Partial<Tenant>,
  name: string,
  emailData: PDFEmailData,
): Promise<string> {
  const colors = ref({ primary: "#000000", secondary: "#000000", customized: false })
  colors.value.primary = tenant.invoice_color ?? "#000000"
  colors.value.secondary = getSecondaryColor(colors.value.primary)
  await client.refresh()
  await axios.post(
    config.sendQuoteEmailUrl,
    {
      "project": projectId,
      "tenant": localStorage.getItem("tenant"),
      "name": name,
      "auth_token": await client.getToken(),
      "language": localStorage.getItem("tredco-app-language"),
      "directus_auth": localStorage.getItem("directus-data"),
      "primary-color": colors.value.primary,
      "secondary-color": colors.value.secondary,
      "data": emailData,
    },
    {
      responseType: "blob",
    },
  )
  return ""
}

export const quotes = {
  getProject,
  getHighestQuoteNumber,
  newProject,
  updateProject,
  getPosts,
  getPost,
  updatePost,
  newPost,
  newPostTemplate,
  newPhase,
  updatePhase,
  linkFileAndPost,
  downloadPdf,
  sendQuoteEmail,
}
