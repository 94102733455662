<template>
  <BottomDrawerSlot
    :title="employeeTitle"
    :show-options="employeeController.shouldShowEditEmployeeDrawer"
    @close="employeeController.cancelCreating"
  >
    <div
      v-if="employeeController.activeEmployee !== null"
      class="min-h-[55vh] flex flex-col justify-between"
    >
      <div class="space-y-4">
        <Badge
          v-if="employeeController.status === 'editing'"
          class="ml-2"
          :variant="badgeVariant"
        >
          {{ $t(`employees.status.${employeeController.activeEmployee.status}`) }}
        </Badge>

        <PhoneInput
          v-model="employeeController.activeEmployee.phone_number"
          name="phone_number"
          :label="$t('phone')"
          class="mb-3"
          :allow-all-countries="true"
          :readonly="employeeController.status === 'editing'"
        />

        <SelectBox
          translation-path="company-settings.your-account-settings.jobTitleOptions"
          class="w-full"
          :selected-value="employeeController.activeEmployee.job_title"
          :label="$t('company-settings.your-account-settings.jobTitle')"
          :invert-background="true"
          @select="employeeController.updateEmployee({ job_title: $event })"
        />

        <SelectBox
          translation-path="employees.roleOptions"
          class="w-full"
          :selected-value="employeeController.activeEmployee.role"
          :label="$t('employees.role')"
          :invert-background="true"
          @select="employeeController.updateEmployee({ role: $event })"
        />

        <LimitedOptions
          v-if="employeeController.activeEmployee?.role === 'employee'"
          :options="accessOptions"
          :label="$t('employees.access_management')"
          type="multiple"
          @selected-item="employeeController.handleAccessUpdate"
        />
        <div v-else>
          <p class="italic text-label-3 text-color-label-3">
            {{ $t("employees.access_management_description") }}
          </p>
        </div>
      </div>
      <div class="my-4">
        <Button
          v-if="
            employeeController.status === 'editing'
              && employeeController.activeEmployee.status === 'active'
          "
          size="large"
          type="secondary"
          class="w-full mb-2"
          :loading="employeeController.isLoading"
          @click="employeeController.deactivateEmployee"
        >
          <span class="text-color-warning">{{
            $t("employees.deactivate_employee")
          }}</span>
        </Button>
        <Button
          v-if="
            employeeController.status === 'editing'
              && employeeController.activeEmployee.status === 'inactive'
          "
          size="large"
          type="secondary"
          class="w-full mb-2"
          :loading="employeeController.isLoading"
          @click="employeeController.activateEmployee"
        >
          <span class="text-color-success">{{ $t("employees.activate_employee") }}</span>
        </Button>
        <!--
        <Button
          v-if="employeeController.status === 'editing'"
          size="large"
          type="secondary"
          class="w-full mb-2"
          :loading="employeeController.isLoading"
        >
          <span class="text-color-error">{{ $t("employees.archive_employee") }}</span>
        </Button>
        -->
        <Button
          v-if="employeeController.status === 'editing'"
          size="large"
          class="w-full"
          :loading="employeeController.isLoading"
          @click="employeeController.saveEmployee"
        >
          {{ $t("shared.actions.save") }}
        </Button>
        <Button
          v-if="employeeController.status === 'creating'"
          size="large"
          class="w-full mb-2"
          :loading="employeeController.isLoading"
          @click="employeeController.createEmployee"
        >
          {{ $t("employees.invite_employee") }}
        </Button>
      </div>
    </div>
  </BottomDrawerSlot>

  <MobileDialog
    :loading="false"
    :sub-title="$t('employees.archive_consequences')"
    :title="$t('shared.confirmations.make_sure')"
    :show="showArchiveConfirmation"
    @y="confirmArchiveEmployee"
    @n="showArchiveConfirmation = false"
  />
</template>

<script setup lang="ts">
import { useEmployeeController } from "@/stores/employeeController"
import { useI18n } from "vue-i18n"

const { t } = useI18n()
const employeeController = useEmployeeController()
const showArchiveConfirmation = ref(false)

const employeeTitle = computed(() => {
  if (!employeeController.activeEmployee || !employeeController.activeEmployee.id) { return t("employees.new_employee") }
  if (employeeController.activeEmployee.status === "invited") { return t("employees.waiting_for_invite_acceptance") }
  return `${employeeController.activeEmployee.user.first_name} ${employeeController.activeEmployee.user.last_name}`
})

const employeeStatusBadgeMap = inject("employeeStatusBadgeMap") as Record<
  string,
  BadgeVariant
>
const badgeVariant = computed(() => {
  if (!employeeController.activeEmployee) { return "default" }
  return employeeStatusBadgeMap[employeeController.activeEmployee.status] || "default"
})

function confirmArchiveEmployee() {
  showArchiveConfirmation.value = false
  employeeController.archiveEmployee()
}

const accessOptions = computed(() => {
  const access = employeeController.activeEmployee?.access
  return [
    {
      id: "account_management",
      label: t("employees.access.account_management"),
      subLabel: t("employees.access.account_management_description"),
      selected: access?.account_management ?? false,
    },
    {
      id: "employee_management",
      label: t("employees.access.employee_management"),
      subLabel: t("employees.access.employee_management_description"),
      selected: access?.employee_management ?? false,
    },
  ]
})
</script>
