<template>
  <div>
    <Card>
      <CardHeader>
        <CardTitle>
          <div class="flex items-center justify-between">
            <div v-if="employee.status !== 'invited'">
              {{ employee.user.first_name }} {{ employee.user.last_name }}
            </div>
            <template v-else>
              <div class="flex items-center">
                <InformationCircleIcon class="w-5 h-5 mr-1 text-color-warning" />
                <p
                  class="italic break-words whitespace-normal text-label-3 text-color-label-3"
                >
                  {{ $t("employees.waiting_for_invite_acceptance") }}
                </p>
              </div>
            </template>
            <Badge :variant="badgeVariant">
              {{
                $t(`employees.status.${employee.status}`)
              }}
            </Badge>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <a
          class="text-color-link-static"
          :href="`tel:${getLocalPhoneNumber(employee.user.phone_number)}`"
          @click.stop
        >
          {{ getLocalPhoneNumber(employee.user.phone_number) }}
        </a>
        <p class="text-label-3 text-color-label-3">
          {{
            $t(
              `company-settings.your-account-settings.jobTitleOptions.${employee.job_title}`,
            )
          }}
        </p>
      </CardContent>
    </Card>
  </div>
</template>

<script setup lang="ts">
import type { IEmployee } from "@/types/DateTypes"
import { InformationCircleIcon } from "@heroicons/vue/24/solid"

interface Props {
  employee: IEmployee
}
const props = defineProps<Props>()

const employeeStatusBadgeMap = inject("employeeStatusBadgeMap") as Record<
  string,
  BadgeVariant
>
const badgeVariant = computed(
  () => employeeStatusBadgeMap[props.employee.status] || "default",
)
</script>
