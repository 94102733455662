<template>
  <PageLayout>
    <template #header>
      <TopNav
        :title="$t('quote.settings.payment_plan')"
        :show-additiol-options="false"
        type="edit"
        :saving="saving"
        @back="$router.back()"
      />
    </template>
    <template #default>
      <p class="mb-4 text-label-3 text-color-label-3">
        {{ $t("company-settings.payment_plan.explanation") }}
      </p>
      <SettingsPaymentPlan v-model="paymentItems" />
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { PaymentPlan } from "@/types"
import { cloneDeep, isEqual } from "lodash"

const globalStore = useGlobalStore()

const saving = ref(false)
const paymentItems = ref<PaymentPlan[]>(cloneDeep(globalStore.tenant.payment_plan) || [])

watch(
  paymentItems,
  async (payment_plan) => {
    if (!globalStore.tenant || isEqual(payment_plan, globalStore.tenant.payment_plan)) {
      return
    }

    saving.value = true
    globalStore.tenant.payment_plan = paymentItems.value
    await api.users.updateMyTenant(globalStore.tenant.id, {
      payment_plan: paymentItems.value,
    })
    saving.value = false
  },
  { deep: true },
)
</script>

<style scoped></style>
