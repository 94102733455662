<template>
  <div v-if="projectStore.project" class="rounded-2xl bg-1-static">
    <div class="space-y-3 pb-8">
      <StringList
        v-model="quoteEmailStore.emailData.email"
        :min-values="1"
        :title="$t('email')"
        :add-value-text="$t('add_recipient')"
        class="my-3"
      />
      <p class="text-heading-3 text-color-label-1">
        {{ $t("add_cc_recipient") }}
      </p>
      <StringList
        v-model="quoteEmailStore.emailData.cc"
        :min-values="1"
        :title="$t('email')"
        :add-value-text="$t('add_cc_recipient')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProjectStore } from "@/stores/projectStore"
import { useQuoteEmailStore } from "@/stores/quoteEmailStore"

const emit = defineEmits(["back"])
const projectStore = useProjectStore()
const quoteEmailStore = useQuoteEmailStore()
</script>
