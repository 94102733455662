<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3285 4.22183C15.6953 2.85499 17.9114 2.85499 19.2782 4.22183C20.645 5.58866 20.645 7.80474 19.2782 9.17157L16.8033 11.6464L18.2176 13.0607L16.8033 14.4749L15.3891 13.0607L8.63613 19.8136L5.61971 19.9996C4.41999 20.0735 3.42651 19.08 3.50046 17.8803L3.68639 14.8639L10.4394 8.11091L9.02517 6.6967L10.4394 5.28249L11.8536 6.6967L14.3285 4.22183ZM5.63601 15.7427L11.8536 9.52512L13.9749 11.6464L7.75733 17.864L5.49667 18.0034L5.63601 15.7427Z"
      fill="#0055FF"
    />
  </svg>
</template>

<script setup lang="ts"></script>
