<template>
  <div class="rounded-t-3xl bg-1-static ">
    <Logo
      :button-title="$t('onboarding.continue_button')"
      :secondary-button-title="$t('onboarding.logo_7')"
      @updated="onboardingStore.status = 'completed'"
      @secondry-clicked="onboardingStore.status = 'completed'"
    />
  </div>
</template>

<script setup lang="ts">
import { useOnboardingStore } from "@/stores/onboardingStore"

const onboardingStore = useOnboardingStore()
</script>
