<template>
  <div :class="badgeClasses">
    <slot />
  </div>
</template>

<script setup lang="ts">
export type BadgeVariant =
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "success"
  | "done"
  | "default"

const props = defineProps<{
  variant?: BadgeVariant
  class?: string
}>()

const badgeClasses = computed(() => {
  const baseClasses = [
    "inline-flex",
    "items-center",
    "rounded-full",
    "border",
    "px-2.5",
    "py-0.5",
    "text-xs",
    "font-semibold",
    "transition-colors",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-neutral-950",
    "focus:ring-offset-2",
  ].join(" ")

  const variantClasses = {
    default: "border-transparent bg-neutral-900 text-neutral-50 hover:bg-neutral-900/80",
    primary: "border-transparent bg-primary text-white hover:bg-hover",
    secondary:
      "border-transparent bg-secondary text-color-button-secondary hover:bg-button-secondary-hover",
    error: "border-transparent bg-error text-white hover:bg-semantic-error/80",
    warning: "border-transparent bg-warning text-white hover:bg-color-warning/80",
    success: "border-transparent bg-success text-white hover:bg-color-success/80",
    done: "border-transparent bg-done text-white hover:bg-color-done/80",
  }

  return `${baseClasses} ${variantClasses[props.variant || "default"]} ${props.class || ""}`
})
</script>
