<template>
  <div>
    <Button
      class="w-full desktop:w-auto"
      type="secondary"
      size="large"
      @click="versionStore.reloadApp"
    >
      <span v-if="!versionStore.updateAvailable">{{ $t("app_up_to_date") }} </span>
      <span v-if="versionStore.updateAvailable">{{ $t("app_update_available") }} ({{ versionStore.versionSnippetNew }})
      </span>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { useVersionStore } from "@/stores/appVersionManagementStore"

const versionStore = useVersionStore()
</script>
