<template>
  <SettingsPaymentPlan v-model="paymentItems" mode="quote-settings" class="mt-3" />
</template>

<script setup lang="ts">
import type { PaymentPlan } from "@/types"
import { cloneDeep, isEqual } from "lodash"

const projectStore = useProjectStore()

const saving = ref(false)
const paymentItems = ref<PaymentPlan[]>(
  cloneDeep(projectStore.project.payment_plan) || [],
)

provide<number>("amount", projectStore.totalIncVat)

watch(
  paymentItems,
  async (payment_plan) => {
    if (
      !projectStore.project
      || isEqual(payment_plan, projectStore.project.payment_plan)
    ) {
      return
    }
    saving.value = true
    projectStore.project.payment_plan = payment_plan
    await projectStore.updateProject()
    saving.value = false
  },
  { deep: true },
)
</script>
