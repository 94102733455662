import type { DirectusContext } from "@/types"
import api from "@/api"
import { debounce } from "lodash"
import { useField } from "vee-validate"
import { ref } from "vue"

interface TextAreaStateProps {
  modelValue?: string | null
  name: string
  rules?: string
  directusContext?: DirectusContext
}

export function useTextAreaState(
  props: TextAreaStateProps,
  emit: (event: "update:modelValue" | "saving" | "blur", ...args: any[]) => void,
) {
  const loading = ref(false)
  const showSpinner = ref(false)
  const hasSpinnerBeenShown = ref(false)

  const { value: fieldValue, handleBlur: validateOnBlur } = useField(
    props.name,
    props.rules,
    {
      initialValue: props.modelValue,
    },
  )

  async function updateFieldOnServer() {
    if (!props.directusContext) { return }

    const { collection, property, id } = props.directusContext
    if (!collection || !property || !id) { return }

    loading.value = true
    emit("saving", true)
    await api.updateGeneric({ collection, id, data: { [property]: props.modelValue } })
    emit("saving", false)
    loading.value = false
  }

  const debouncedUpdate = debounce(updateFieldOnServer, 1000)

  watch(
    () => fieldValue.value,
    () => {
      emit("update:modelValue", fieldValue.value)
      debouncedUpdate.cancel()
      debouncedUpdate()
    },
  )

  watch(
    () => props.modelValue,
    () => {
      fieldValue.value = props.modelValue
    },
  )

  watch(
    () => loading.value,
    () => {
      if (!loading.value) {
        setTimeout(() => {
          showSpinner.value = false
        }, 300)
        setTimeout(() => {
          hasSpinnerBeenShown.value = false
        }, 1000)
      }
      else {
        showSpinner.value = true
        hasSpinnerBeenShown.value = true
      }
    },
    { immediate: true },
  )

  return {
    fieldValue,
    validateOnBlur,
    loading,
    showSpinner,
    hasSpinnerBeenShown,
  }
}
