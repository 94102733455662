<template>
  <div class="mt-8 rounded-xl bg-1-static">
    <div>
      <Toggle
        :value-left="$t('quote.settings.percentage')"
        :value-right="$t('quote.settings.spesific_amount')"
        class="mb-4"
        @left-click="showPercentage = true"
        @right-click="showPercentage = false"
      />

      <TextFieldVal
        v-if="showPercentage"
        v-model="percent"
        :label="$t('quote.settings.percentage')"
        type="number"
        class="mb-3"
        suffix="%"
      />
      <TextFieldVal
        v-if="!showPercentage"
        v-model="discount"
        :label="$t('amount')"
        type="number"
        :select-content="true"
        class="mb-3"
        :suffix="$t('quote.currency')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProjectStore } from "@/stores/projectStore"

const emit = defineEmits(["back"])

const projectStore = useProjectStore()

const showPercentage = ref(false)
const discount = ref(Number(projectStore.project.discount_amount || 0))
const percent = ref("0")
if (discount.value > 0) {
  percent.value = formatNumber(
    (discount.value / projectStore.grandTotal) * 100,
  ).toString()
}

watch([percent, showPercentage], async (newVal, oldVal) => {
  if (newVal[0] === oldVal[0] || showPercentage.value === false || newVal[0] === "") {
    return
  }

  discount.value = Math.round(projectStore.grandTotal * (Number(percent.value) / 100))
  projectStore.project.discount_amount = Math.round(discount.value)
  await projectStore.updateProject()
})

watch([discount, showPercentage], async (newVal, oldVal) => {
  if (
    Number(newVal[0]) === Number(oldVal[0])
    || showPercentage.value === true
    || newVal[0] === 0
  ) {
    return
  }

  projectStore.project.discount_amount = Math.round(discount.value)
  percent.value = formatNumber(
    (Number(discount.value) / projectStore.grandTotal) * 100,
  ).toString()

  await projectStore.updateProject()
})
</script>
