<template>
  <div class="desktop:rounded-3xl h-full rounded-t-3xl bg-static px-6  desktop:border">
    <div class="mb-12 mt-20">
      <p class="mb-2 text-heading-2 text-white" @click="() => mainTextClicked++">
        {{ $t("onboarding.landing_1") }}
      </p>
      <p class="text-label-1 text-white">
        {{ $t("onboarding.landing_2") }}
      </p>
    </div>
    <div class="py-5">
      <OnboardingButton
        id="create-account"
        class="mb-4 w-full"
        type="primary"
        size="large"
        @click="onboardingStore.status = 'country'"
      >
        {{ $t("onboarding.landing_create_account") }}
      </OnboardingButton>

      <div class="mb-4 w-full rounded-full bg-1-static text-white">
        <OnboardingButton
          class="w-full"
          size="large"
          type="secondary"
          @click="$router.push({ name: 'auth.loginOptions' })"
        >
          {{ $t("auth.login") }}
        </OnboardingButton>
      </div>

      <div class="w-full px-6">
        <p class="text-center text-label-2 text-white">
          {{ $t("onboarding.landing_terms_and_conditions-1") }} <br>
          <a
            :href="termsAndConditionsLink"
            target="_blank"
            class="link text-white underline"
          >{{ $t("onboarding.landing_terms_and_conditions") }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Toaster } from "@/types"
import { useOnboardingStore } from "@/stores/onboardingStore"
import { useI18n } from "vue-i18n"

defineEmits(["next"])
const onboardingStore = useOnboardingStore()
const { locale } = useI18n({ useScope: "global" })
const toast = inject<Toaster>("toast") as Toaster

const termsAndConditionsLink = computed(() => {
  return "https://tredco.no/brukervilkar/"
})

const mainTextClicked = ref(0)

watch(mainTextClicked, () => {
  if (mainTextClicked.value === 3 && onboardingStore.tenantType !== "test-onboarding") {
    onboardingStore.tenantType = "test-onboarding"
    toast.info("This is set to a test tenant")
    mainTextClicked.value = 0
  }
  if (mainTextClicked.value === 3 && onboardingStore.tenantType === "test-onboarding") {
    onboardingStore.tenantType = "prod"
    mainTextClicked.value = 0
    toast.success("This is now set to a production tenant")
  }
})
</script>
