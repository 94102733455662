<template>
  <PageLayout :form-page="true">
    <template #header>
      <TopNav
        :title="$t('company-settings.quote')"
        :show-additiol-options="false"
        type="edit"
        :saving="settingsUpdated"
        @back="$router.back()"
      />
    </template>
    <template #default>
      <form>
        <div class="py-3">
          <NumberField
            v-model="globalStore.tenant.invoice_due_in_days"
            :name="$t('payment_terms')"
            :directus-context="{
              ...directusContext,
              property: 'invoice_due_in_days',
            }"
          />
        </div>
        <div class="py-3">
          <NumberField
            v-model="globalStore.tenant.quote_expire_in_days"
            :name="$t('quote_expire_in_days')"
            :directus-context="{
              ...directusContext,
              property: 'quote_expire_in_days',
            }"
          />
        </div>

        <div class="rounded-xl bg-2-static">
          <CheckBox
            v-model="globalStore.tenant.visible_vat"
            name="visible_vat"
            :label="$t('show_vat')"
            :directus-context="{
              ...directusContext,
              property: 'visible_vat',
            }"
            :show-divider="true"
          />

          <CheckBox
            v-model="globalStore.tenant.visible_post_qty"
            :label="$t('show_quantities')"
            :directus-context="{
              ...directusContext,
              property: 'visible_post_qty',
            }"
            :show-divider="true"
          />
          <CheckBox
            v-model="globalStore.tenant.visible_post_price"
            :label="$t('show_price')"
            :directus-context="{
              ...directusContext,
              property: 'visible_post_price',
            }"
            :show-divider="true"
          />
          <CheckBox
            v-model="globalStore.tenant.visible_post_description"
            :label="$t('show_post_details')"
            :directus-context="{
              ...directusContext,
              property: 'visible_post_description',
            }"
          />
        </div>
      </form>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types"
import { useGlobalStore } from "@/stores/globalStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"

const globalStore = useGlobalStore()
const tenantSettingsStore = useTenantStore()

if (!globalStore.tenant.terms) {
  await tenantSettingsStore.refreshTenant()
}

const settingsUpdated = ref(false)
watch(
  () => globalStore.tenant,
  () => {
    settingsUpdated.value = true
    setTimeout(() => {
      settingsUpdated.value = false
    }, 500)
  },
  { deep: true },
)

const directusContext: DirectusContext = {
  collection: "tenants",
  id: globalStore.tenant.id,
}
</script>
