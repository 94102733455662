import { config } from "@/config"
import { Browser } from "@capacitor/browser"
import { Capacitor } from "@capacitor/core"
import { defineStore } from "pinia"
import { toValue } from "vue"
import { useDataStore } from "./dataStore"
import { i18n } from "@/localization/i18n"

interface PaymentIntentResponse {
  data?: {
    client_secret?: string
    ephemeral_key?: string
    id?: string
    session?: string
  }
  status?: string
  code?: number
  message?: string
}

interface SubscriptionState {
  loading: boolean
  clientSession: string
}

export const useSubscriptionStore = defineStore("subscription", {
  state: (): SubscriptionState => ({
    loading: false,
    clientSession: "",
  }),

  actions: {
    getSubscriptionUrl(clientSession: string) {
      const dataStore = useDataStore()
      const tenant = toValue(
        dataStore.currentUser?.$tenant && dataStore.currentUser?.$tenant,
      )

      if (!tenant) { throw new Error("Tenant not found") }
      if (!dataStore.currentUser?.email) { throw new Error("User email not found") }

      const params = new URLSearchParams({
        tenantId: tenant.id,
        userEmail: dataStore.currentUser.email,
        isProduction: tenant.is_production.toString(),
        clientSession,
        isMobile: Capacitor.isNativePlatform().toString(),
        tenantName: tenant.name,
        language: i18n.global.locale.value
      } as Record<string, string>)

      return `${config.subscriptionSiteUrl}?${params.toString()}`
    },

    async handleSubscribe() {
      try {
        await this.fetchClientForPricingTable()
        const subscriptionUrl = this.getSubscriptionUrl(this.clientSession)

        if (Capacitor.isNativePlatform()) {
          // For native platforms, open in external browser
          await Browser.open({ url: subscriptionUrl, windowName: "_system" })
        }
        else {
          // For web, redirect in same window
          window.open(subscriptionUrl, "_blank")
        }
      }
      catch (error) {
        console.error("Error redirecting to subscription site:", error)
        throw error
      }
    },

    async goToBillingPortal() {
      try {
        this.loading = true
        const dataStore = useDataStore()
        const tenant = toValue(
          dataStore.currentUser?.$tenant && dataStore.currentUser?.$tenant,
        )

        if (!tenant) { throw new Error("Tenant not found") }

        if (tenant.subscription_status !== "stripe_active") {
          await this.fetchClientForPricingTable()
          this.handleSubscribe()
          return
        }

        const webhookPath = tenant.is_production
          ? "/stripe-client-portal-live"
          : "/stripe-client-portal-test"

        const response = (await api.callN8nWorkflow({
          tenant_id: tenant.id,
          path: webhookPath,
        })) as { data?: { session_url?: string } }

        if (!response?.data?.session_url) {
          throw new Error("Invalid response from billing portal")
        }

        if (Capacitor.isNativePlatform()) {
          await Browser.open({ url: response.data.session_url, windowName: "_system" })
        }
        else {
          window.open(response.data.session_url, "_blank")
          // window.location.href = response.data.session
        }
      }
      catch (error) {
        console.error("Error redirecting to billing portal:", error)
        throw error
      }
      finally {
        this.loading = false
      }
    },

    async fetchClientForPricingTable() {
      this.loading = true
      try {
        const dataStore = useDataStore()
        const tenant = toValue(
          dataStore.currentUser?.$tenant && dataStore.currentUser?.$tenant,
        )

        if (!tenant) { throw new Error("Tenant not found") }

        const webhook_path = tenant.is_production
          ? "/stripe-client-session-live"
          : "/stripe-client-session-test"

        const response = (await api.callN8nWorkflow({
          tenant_id: tenant.id,
          path: webhook_path,
        })) as { data?: { session?: string } }

        if (response?.data?.session) {
          this.clientSession = response.data.session
        }
      }
      finally {
        this.loading = false
      }
    },
  },
})
