import type { App } from "vue"
import { createToaster } from "@meforma/vue-toaster"

export const toast = createToaster({
  position: "bottom",
  useDefaultCss: false, // assets/components/toaster.css
})

export default {
  install: (app: App) => {
    app.config.globalProperties.$toast = toast
    app.provide("toast", toast)
  },
}
