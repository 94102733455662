<template>
  <button
    :type="button"
    class="base"
    :class="[
      {
        'py-[14px] text-label-bold-1': size === 'large',
        'py-[10px] text-label-bold-2': size === 'medium',
        'py-[8px] text-label-bold-2': size === 'small',
        'border': type === 'secondary' && !hideBorder,
        'opacity-50': disabled,
      },
      type,
    ]"
    :disabled="disabled || loading"
  >
    <IconAdd v-if="icon && !showSpinner" :class="type" />
    <AtomSpinner
      v-if="showSpinner"
      :size="15"
      :color="type === 'primary' ? 'white' : 'blue'"
    />
    <MaterialSymbolsCheckSmall v-if="!showSpinner && hasSpinnerBeenShown" :size="20" />
    <span>
      <slot />
    </span>
  </button>
</template>

<script setup lang="ts">
interface Props {
  loading?: boolean
  disabled?: boolean
  size?: "large" | "medium" | "small"
  type?: "primary" | "secondary"
  hideBorder?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  disabled: false,
  size: "medium",
  type: "primary",
  submit: false,
  icon: false,
  hideBorder: false,
})

const showSpinner = ref(false)
const hasSpinnerBeenShown = ref(false) // Prevents spinner from showing on first render

watch(
  () => props.loading,
  () => {
    if (!props.loading) {
      setTimeout(() => {
        showSpinner.value = false
      }, 300)
      setTimeout(() => {
        // Add this setTimeout to hide MaterialSymbolsCheckSmall after 1 second
        hasSpinnerBeenShown.value = false
      }, 1000)
    }
    else {
      showSpinner.value = true
      hasSpinnerBeenShown.value = true // Update this value when the spinner is shown
    }
  },
  { immediate: true },
)
</script>

<style lang="postcss" scoped>
button.base {
  @apply flex items-center justify-center space-x-2;
  @apply px-7;
  @apply rounded-[24px];
  @apply truncate disabled:cursor-not-allowed print:hidden;
}

button.primary {
  @apply border-button-primary border-opacity-10;
  @apply bg-black;
  @apply text-color-button-primary;
  @apply transition-none;
}

button.secondary {
  @apply bg-button-secondary-static;
  @apply border-button-secondary;
  @apply text-color-button-secondary;
  @apply transition-none;
}
</style>
