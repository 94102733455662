<template>
  <div ref="pdfPageContainer">
    <div ref="pdfPage" class="bg-gray pdf-viewer-mobile w-min origin-top-left">
      <QuotePDFDocument
        :project="project as PdfProject"
        :tenant="tenant"
        :colors="colors"
        :user="globalStore.user"
        :job-title="globalStore.employee?.job_title"
        class="origin-top-left"
        @ready="scalePDFPage"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PdfProject } from "@/pdf-generator/types"
import type { Project, Tenant } from "@/types"

defineProps<{
  project: Project
  tenant: Tenant
  colors: color
}>()

const globalStore = useGlobalStore()

const accessToken = await api.client.getToken()
function assetUrl(assetId: string) {
  return `${api.client.url}/assets/${assetId}?access_token=${accessToken}`
}

provide("assetUrl", assetUrl)

interface color { primary: string, secondary: string, customized: boolean }

const pdfPage = ref<HTMLElement | null>(null)
const pdfPageContainer = ref<HTMLElement>()

watchEffect(scalePDFPage)

const bgColorsInverted = inject("bgColorsInverted") as Ref<boolean>
const mobile = inject("isPhone") as Ref<boolean>

onMounted(() => {
  window.addEventListener("resize", scalePDFPage)
  if (!mobile.value) {
    bgColorsInverted.value = false
  }
})

onUnmounted(() => {
  window.removeEventListener("resize", scalePDFPage)
  if (!mobile.value) {
    bgColorsInverted.value = true
  }
})

function scalePDFPage() {
  if (pdfPage.value && pdfPageContainer.value) {
    const currentWidth = pdfPage.value.clientWidth
    const expectedWidth = pdfPageContainer.value.clientWidth
    const scale = expectedWidth / currentWidth
    pdfPage.value.style.scale = scale.toString()
    pdfPageContainer.value.style.height = `${pdfPage.value.clientHeight * scale}px`
  }
}
</script>

<style>
.pdf-viewer-mobile .page {
  @apply mb-10 rounded-xl border bg-1-static shadow-lg;
}
</style>
