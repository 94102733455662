<template>
  <section
    class="fixed inset-0 z-50 overflow-y-hidden"
    :class="showModal ? 'pointer-events-all' : 'pointer-events-none'"
  >
    <div
      class="flex items-end justify-center pt-4 pb-20 text-center min-h-dvh sm:block sm:p-0"
    >
      <div
        :class="
          showModal ? 'pointer-events-all opacity-100' : 'pointer-events-none opacity-0'
        "
        class="fixed inset-0 transition-opacity duration-300 ease-in-out cursor-pointer"
        aria-hidden="true"
      >
        <div class="absolute inset-0 bg-black opacity-80" />
      </div>
      <span class="hidden sm:inline-block sm:h-dvh sm:align-middle" aria-hidden="true">&#8203;</span>
      <div
        ref="containerEl"
        class="dark:bg-charade pointer-events-all z-50 my-8 inline-flex max-h-[85vh] w-full transform rounded bg-white py-6 pb-4 pt-5 text-left align-middle shadow-xl transition-all duration-300 ease-in-out"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        :class="[
          showModal
            ? 'pointer-events-all translate-y-0 opacity-100 sm:scale-100'
            : 'pointer-events-none translate-y-8 opacity-0 sm:translate-y-0 sm:scale-75',
          maxWidthClass,
        ]"
      >
        <IconButton @click="closeModal" />
        <div class="flex max-h-[85vh] flex-1 flex-col space-y-4 py-1">
          <div v-if="slots.header" class="px-8 space-y-4">
            <slot name="header" />
            <hr>
          </div>
          <div class="px-8 overflow-y-auto">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core"

const props = defineProps({
  showModal: Boolean,
  size: { type: String as PropType<"normal" | "large" | "xl">, default: "" },
})

const emit = defineEmits(["close"])

const slots = useSlots()

const maxWidthClass = computed(() => {
  const sizes = {
    normal: "max-w-lg",
    large: "max-w-4xl",
    xl: "max-w-6xl",
  }
  return sizes[props.size]
})
const containerEl = ref()

function closeModal() {
  emit("close")
}

onClickOutside(containerEl, closeModal)
</script>
