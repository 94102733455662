<template>
  <form id="mobile-form" class="w-full" @submit.prevent="submitMobileForm">
    <PhoneInput
      name="mobile"
      class="mb-5"
      :placeholder="t('auth.mobile_number')"
      :focus="true"
    />
    <Button size="large" class="w-full" :loading="loading" submit>
      <slot>
        {{ t("auth.send_sms_code") }}
      </slot>
    </Button>
  </form>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useForm } from "vee-validate"
import * as Yup from "yup"

defineProps<{
  loading: boolean
}>()

const emit = defineEmits<{
  (e: "submit", value: string): void
}>()
const mobileSchema = Yup.object().shape({
  mobile: Yup.number().required().typeError(t("customers.phone.validation_message")),
})
const { handleSubmit: handleMobileSubmit } = useForm<Yup.InferType<typeof mobileSchema>>({
  validationSchema: mobileSchema,
})

const submitMobileForm = handleMobileSubmit((form) => {
  emit("submit", form.mobile.toString())
})
</script>
