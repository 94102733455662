<template>
  <PageLayout :form-page="true">
    <template #header>
      <TopNav
        :title="pageTitle"
        :type="customerStore.status"
        :show-additiol-options="customerStore.status === 'edit'"
        :saving="saving"
        @exit="$router.back()"
        @back="$router.back()"
        @save="submitForm"
        @options="true"
      >
        <template #options>
          <CustomerOptions :id="customerId" />
        </template>
      </TopNav>
    </template>
    <template #default>
      <Toggle
        :value-left="$t('customers.types.company')"
        :value-right="$t('customers.types.person')"
        class="my-4 bg-1-static"
        :default="customerStore.customer.type === 'company' ? 'left' : 'right'"
        @left-click="customerStore.customer.type = 'company'"
        @right-click="customerStore.customer.type = 'person'"
      />
      <TextFieldWithAutoComplete
        v-if="customerStore.showBrregSearchBox"
        ref="nameInputEl"
        v-model="customerStore.query"
        :name="$t('customers.company_name')"
        :options="customerStore.companies ?? []"
        @select="customerStore.handleSelect"
        @search="customerStore.searchBrreg"
      >
        <template #option="{ option }">
          <BrregSummary :company="option as BrregCompany" />
        </template>
      </TextFieldWithAutoComplete>
      <p class="text-heading-3 text-color-label-1">
        {{ customerStore.customer.name }}
      </p>
      <p class="text-label-3 text-color-label-3">
        {{ orgNo }}
      </p>
      <div v-show="!customerStore.showBrregSearchBox" class="mt-4">
        <form class="w-full space-y-4" @submit.prevent="submitForm">
          <TextFieldVal
            v-model="customerStore.customer.name"
            rules="required"
            type="text"
            :label="$t('shared.fields.name')"
            name="name"
            :directus-context="directusContext"
            directus-property="name"
            @saving="saving = $event"
          />
          <TextFieldVal
            v-model="customerStore.customer.email"
            rules="email|required"
            type="email"
            :label="$t('email')"
            name="email"
            :directus-context="directusContext"
            directus-property="email"
            @saving="saving = $event"
          />
          <PhoneInput
            v-model="customerStore.customer.phone_number"
            name="phone_number"
            :label="$t('phone')"
            class="mb-3"
            :directus-context="directusContext"
            directus-property="phone_number"
            :allow-all-countries="true"
          />

          <TextFieldVal
            v-model="customerStore.customer.street_address"
            type="text"
            :label="$t('street_address')"
            name="street_address"
            :directus-context="directusContext"
            directus-property="street_address"
            @saving="saving = $event"
          /><TextFieldVal
            v-model="customerStore.customer.zip_code"
            type="text"
            :label="$t('postal_code')"
            name="zip_code"
            :directus-context="directusContext"
            directus-property="zip_code"
            @saving="saving = $event"
          />
          <TextFieldVal
            v-model="customerStore.customer.city"
            type="text"
            :label="$t('city')"
            name="city"
            :directus-context="directusContext"
            directus-property="city"
            @saving="saving = $event"
          />
          <TextFieldVal
            v-model="customerStore.customer.municipality"
            type="text"
            :label="$t('municipality')"
            name="municipality"
            :directus-context="directusContext"
            directus-property="municipality"
            @saving="saving = $event"
          />
          <!-- <TextFieldVal
          v-model="customerStore.customer.province"
          type="text"
          :label="$t('province')"
          name="province"
          :directus-context="directusContext"
          directus-property="name"
        /> -->
          <p class="text-heading-3 text-color-label-1">
            {{ $t("invoice_information") }}
          </p>
          <TextFieldVal
            v-model="customerStore.customer.billing_zip_code"
            type="text"
            :label="$t('postal_code')"
            name="billing_zip_code"
            :directus-context="directusContext"
            directus-property="billing_zip_code"
            @saving="saving = $event"
          />
          <TextFieldVal
            v-model="customerStore.customer.billing_city"
            type="text"
            :label="$t('city')"
            name="billing_city"
            :directus-context="directusContext"
            directus-property="billing_city"
            @saving="saving = $event"
          />
          <TextFieldVal
            v-model="customerStore.customer.billing_municipality"
            type="text"
            :label="$t('municipality')"
            name="billing_municipality"
            :directus-context="directusContext"
            directus-property="billing_municipality"
            @saving="saving = $event"
          />
          <!-- <TextFieldVal
          v-model="customerStore.customer.billing_province"
          type="text"
          :label="$t('province')"
          name="billing_province"
        /> -->
          <TextArea
            v-model="customerStore.customer.notes"
            :name="$t('notes')"
            :placeholder="$t('your_text')"
            class="mb-4"
            :directus-context="{
              ...directusContext,
              property: 'notes',
            }"
            @saving="saving = $event"
          />
          <!-- <Button
              v-if="customerStore.status === 'new'"
              class="w-full"
              size="large"
              type="primary"
              submit
              @click="handleSubmit"
              >{{ $t("shared.actions.save") }}</Button
            > -->
        </form>
      </div>
    </template>
    <template #footer-mobile />
  </PageLayout>
</template>

<script setup lang="ts">
import type { BrregCompany, Customer, DirectusContext } from "@/types"
import { useCustomerStore } from "@/stores/customerStore"
import { useProjectStore } from "@/stores/projectStore"
import { useForm } from "vee-validate"
import { useRouter } from "vue-router"

const saving = ref(false)

const { t } = useI18n()
const globalStore = useGlobalStore()
const customerStore = useCustomerStore()
const route = useRoute()
const { handleSubmit } = useForm()
const router = useRouter()
const projectStore = useProjectStore()

const submitForm = handleSubmit(async () => {
  customerStore.customer.tenant = globalStore.tenant.id
  const customer = await customerStore.create()
  if (
    projectStore.status === "new-pick-customer"
    || projectStore.status === "edit-pick-customer"
  ) {
    await addClientToProject(customer)
  }
  else {
    router.push({ name: "customers" })
  }
})

const pageTitle = computed(() => {
  if (customerStore.status === "edit") {
    return t("customers.edit_customer")
  }
  else {
    return t("customers.new_customer")
  }
})

async function addClientToProject(customer: Customer) {
  if (projectStore.status === "new-pick-customer") {
    projectStore.project.customer = customer.id
    projectStore.status = "edit"
    projectStore.project.name = customer.street_address ?? ""
    await projectStore.saveProject()
  }
  else if (projectStore.status === "edit-pick-customer") {
    projectStore.project.customer = customer
    await projectStore.updateProject()
  }
  await router.push({
    name: "quote",
    params: { quoteId: projectStore.project.id },
  })
}

const customerId = route.params.id as string
if (customerId) {
  customerStore.status = "edit"
  await customerStore.loadCustomer(customerId)
}

let directusContext: DirectusContext | undefined
if (customerStore.status === "edit") {
  directusContext = {
    collection: "customers",
    id: customerId,
  }
}

const orgNo = computed(() => {
  if (!customerStore.customer.company_registration_number) { return "" }

  return `${t("org_number_abbrev")}: ${
    customerStore.customer.company_registration_number
  }`
})
</script>
