<template>
  <div class="m-0 h-dvh w-full bg-1-static p-0">
    <div>
      <div>
        <div class="mb-4 flex items-center justify-between pl-4">
          <div class="flex items-center">
            <IconArrowBackward class="mt-8" @click="navigateBack" />
            <h1 class="ml-4 mt-8 text-label-bold-1">
              {{ $t("company-settings.general") }}
            </h1>
          </div>
          <div class="mt-8">
            <SaveState :is-loading="tenantSettingsStore.isUpdatingProfile" />
          </div>
        </div>
      </div>
      <hr class="border-1 border-divider">
      <div>
        <div class="p-4">
          <form>
            <TextFieldVal
              v-model="first_name"
              name="first_name"
              :label="$t('company-settings.your-account-settings.firstName')"
              :placeholder="$t('company-settings.your-account-settings.firstName')"
              type="text"
              text-size="normal"
              :bold="false"
              class="mt-2 pb-4"
              @blur="onSubmit"
            />
            <TextFieldVal
              v-if="globalStore.isDeveloper"
              v-model="last_name"
              name="last_name"
              :label="$t('company-settings.your-account-settings.lastName')"
              :placeholder="$t('company-settings.your-account-settings.lastName')"
              type="text"
              text-size="normal"
              :bold="false"
              class="mt-2 pb-4"
              @blur="onSubmit"
            />
            <TextFieldVal
              v-if="globalStore.isDeveloper"
              v-model="email"
              name="email"
              :label="$t('company-settings.your-account-settings.email')"
              :placeholder="$t('company-settings.your-account-settings.email')"
              type="text"
              text-size="normal"
              :bold="false"
              class="mt-2 pb-4"
              @blur="onSubmit"
            />
          </form>
        </div>
      </div>
      <div class="fixed inset-x-0 bottom-0" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/globalStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"
import { useForm } from "vee-validate"
import { useRouter } from "vue-router"
import * as Yup from "yup"

const globalStore = useGlobalStore()
const tenantSettingsStore = useTenantStore()
const first_name = ref(globalStore.user.first_name)
const last_name = ref(globalStore.user.last_name)
const email = ref(globalStore.user.email)
const { t } = useI18n()
const router = useRouter()

const schema = Yup.object().shape({
  first_name: Yup.string()
    .max(32, t("company-settings.error.first_name_too_long"))
    .required(t("company-settings.error.first_name_required")),
})
const { handleSubmit, errors } = useForm<Yup.InferType<typeof schema>>({
  validationSchema: schema,
})

const onSubmit = handleSubmit(async (values: any) => {
  if (Object.keys(errors.value).length === 0) {
    await tenantSettingsStore.updateProfile(values)
  }
})

async function navigateBack(): void {
  await router.go(-1)
}
</script>
