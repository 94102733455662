import type { Currency, Languages, LimitedOptionsSet } from "@/types"
import { t } from "@/localization/i18n"

export interface CountrySetting {
  vatRate: number
  currencyCode: Currency
  countryCode: string
  localCurrency: string
  language: Languages
}

type CountrySettingsMap = Map<string, CountrySetting>
type CountrySettingsArray = { countryCode: string, settings: CountrySetting }[]

interface CountryData {
  map: CountrySettingsMap
  array: CountrySettingsArray
  limitedOptionsSet: LimitedOptionsSet<CountrySetting>[]
}

const countrySettingsObject: { [key: string]: CountrySetting } = {
  ZA: {
    vatRate: 0.15,
    currencyCode: "ZAR",
    countryCode: "ZA",
    localCurrency: "R",
    language: "en",
  },
  CA: {
    vatRate: 0.05, // GST rate, but note that some provinces have additional provincial sales tax (PST or HST)
    currencyCode: "CAD",
    countryCode: "CA",
    localCurrency: "$",
    language: "en",
  },
  NO: {
    vatRate: 0.25,
    currencyCode: "NOK",
    countryCode: "NO",
    localCurrency: "kr",
    language: "nb",
  },
  FR: {
    vatRate: 0.2,
    currencyCode: "EUR",
    countryCode: "FR",
    localCurrency: "€",
    language: "fr",
  },
}

export function getCountriesConfig(defaultCountryCode?: string): CountryData {
  const countryVatMap: CountrySettingsMap = new Map<string, CountrySetting>()
  const countryVatArray: CountrySettingsArray = []
  const limitedOptionsSet: LimitedOptionsSet<CountrySetting>[] = []

  for (const [countryCode, settings] of Object.entries(countrySettingsObject)) {
    countryVatMap.set(countryCode, settings)
    countryVatArray.push({ countryCode, settings })
    limitedOptionsSet.push({
      label: t(`country.${countryCode.toLowerCase()}`) || countryCode,
      id: countryCode,
      selected: countryCode === defaultCountryCode,
      value: settings,
    })
  }

  return {
    map: countryVatMap,
    array: countryVatArray,
    limitedOptionsSet,
  }
}
