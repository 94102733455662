<template>
  <div>
    <label v-if="label != ''" class="block mb-1 text-base text-color-label-1">
      {{ label }}
    </label>
    <div class="relative min-h-[42px]">
      <IconSearch class="absolute z-10 w-6 h-6 pointer-events-none left-2 top-2" />
      <input
        ref="input"
        v-model="value"
        type="text"
        :placeholder="placeholder ?? $t('search')"
        class="block w-full pl-10 rounded-full border-divider bg-1-static"
      >

      <IconButton v-if="value" class="absolute right-2 top-1" @click.stop="emit('reset')">
        <IconExit />
      </IconButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVModel } from "@vueuse/core"

const props = defineProps({
  modelValue: { type: String, default: "" },
  placeholder: { type: String, default: null },
  label: { type: String, default: "" },
})
const emit = defineEmits(["update:modelValue", "reset"])
const value = useVModel(props, "modelValue", emit)
</script>
