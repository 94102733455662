import type {
  ICustomer,
  ID,
  IEmployee,
  IPhase,
  IPost,
  IProject,
  ITenant,
  IUser,
} from "@/types/DateTypes"
import { defineStore } from "pinia"
import { computed, ref, watchEffect } from "vue"

export const useDataStore = defineStore("data", () => {
  // State
  const currentUser = ref<IUser | null>(null)
  const currentTenant = ref<string | null>(null)
  const project = ref("")
  const projectsById = ref<Record<string, IProject>>({})
  const customersById = ref<Record<string, ICustomer>>({})
  const postsById = ref<Record<string, Record<string, IPost>>>({})
  const postTemplatesById = ref<Record<string, IPost>>({})
  const phasesById = ref<Record<string, Record<string, IPhase>>>({})
  const employeesById = ref<Record<string, IEmployee>>({})
  const tenantsById = ref<Record<string, ITenant>>({})

  // Getters
  const projects = computed(() => {
    const asArray = Object.values(projectsById.value)
    return asArray.sort((a, b) => {
      if (!a.date_created) {
        console.warn(`Project ${a.id} is missing date_created property`)
        return 0
      }
      if (!b.date_created) {
        console.warn(`Project ${b.id} is missing date_created property`)
        return 0
      }
      return b.date_created.getTime() - a.date_created.getTime()
    })
  })

  const tenants = computed(() => Object.values(tenantsById.value))

  // Actions
  function setCurrentTenant(tenantId: string) {
    if (currentTenant.value === tenantId) { return }
    currentTenant.value = tenantId
    // Clear related data
    projectsById.value = {}
    customersById.value = {}
    postsById.value = {}
  }

  function prepareProject(project: IProject): IProject {
    const convertedProject = convertTimestamps(project, [
      "date_issued",
      "date_expire",
      "date_created",
      "date_updated",
    ])

    // Normalize references
    if (convertedProject.customer && typeof convertedProject.customer === "object") {
      convertedProject.customer = (convertedProject.customer as { id: string }).id
    }
    if (convertedProject.tenant && typeof convertedProject.tenant === "object") {
      convertedProject.tenant = (convertedProject.tenant as { id: string }).id
    }

    return convertedProject
  }

  // Data Management Actions
  function setProjects(projects: IProject[]) {
    projectsById.value = {}
    projects.forEach((project) => {
      if (project.status === "archived") { return }
      const convertedProject = prepareProject(project)
      projectsById.value[convertedProject.id] = convertedProject
    })
  }

  function setProject(project: IProject) {
    if (project.status === "archived") { return }
    const convertedProject = prepareProject(project)
    projectsById.value[convertedProject.id] = convertedProject
  }

  function removeProject(projectId: ID<IProject>) {
    delete projectsById.value[projectId]
  }

  function setCustomers(customers: ICustomer[]) {
    customersById.value = {}
    customers.forEach((customer) => {
      const convertedCustomer = convertTimestamps(customer, [
        "date_created",
        "date_updated",
      ])
      customersById.value[convertedCustomer.id] = convertedCustomer
    })
  }

  function setCustomer(customer: ICustomer) {
    const convertedCustomer = convertTimestamps(customer, [
      "date_created",
      "date_updated",
    ])
    customersById.value[convertedCustomer.id] = convertedCustomer
  }

  function removeCustomer(customerId: ID<ICustomer>) {
    delete customersById.value[customerId]
  }

  function setPosts(posts: IPost[]) {
    posts.forEach(post => setPost(post))
  }

  function setPost(post: IPost) {
    if (!postsById.value[post.phase]) {
      postsById.value[post.phase] = {}
    }
    const convertedPost = convertTimestamps(post, ["date_created", "date_updated"])
    postsById.value[post.phase][post.id] = convertedPost
  }

  function removePost(post: IPost) {
    if (postsById.value[post.phase]) {
      delete postsById.value[post.phase][post.id]
    }
  }

  function setEmployees(employees: IEmployee[]) {
    employeesById.value = {}
    employees.forEach((employee) => {
      const convertedEmployee = convertTimestamps(employee, [
        "date_created",
        "date_updated",
      ])
      employeesById.value[convertedEmployee.id] = convertedEmployee
    })
  }

  function setEmployee(employee: IEmployee) {
    const convertedEmployee = convertTimestamps(employee, [
      "date_created",
      "date_updated",
    ])
    employeesById.value[convertedEmployee.id] = convertedEmployee
  }

  function setUser(user: IUser) {
    const convertedUser = convertTimestamps(user, [
      "date_created",
      "date_updated",
      "release_notes_last_seen",
    ])
    currentUser.value = convertedUser
  }

  function setTenants(tenants: ITenant[]) {
    tenantsById.value = {}
    tenants.forEach((tenant) => {
      const convertedTenant = convertTimestamps(tenant, [
        "date_created",
        "date_updated",
        "subscription_trial_expire_at",
      ])
      tenantsById.value[convertedTenant.id] = convertedTenant
    })
  }

  function setTenant(tenant: ITenant) {
    const convertedTenant = convertTimestamps(tenant, [
      "date_created",
      "date_updated",
      "subscription_trial_expire_at",
    ])
    tenantsById.value[convertedTenant.id] = convertedTenant
  }

  function removeTenant(tenantId: ID<ITenant>) {
    delete tenantsById.value[tenantId]
  }

  function setPhases(phases: IPhase[]) {
    phases.forEach(phase => setPhase(phase))
  }

  function setPhase(phase: IPhase) {
    if (!phasesById.value[phase.project]) {
      phasesById.value[phase.project] = {}
    }
    const convertedPhase = convertTimestamps(phase, ["date_created", "date_updated"])
    phasesById.value[phase.project][phase.id] = convertedPhase
  }

  function removePhase(phase: IPhase) {
    if (phasesById.value[phase.project]) {
      delete phasesById.value[phase.project][phase.id]
    }
  }

  function setPostTemplates(templates: IPost[]) {
    postTemplatesById.value = {}
    templates.forEach((template) => {
      const convertedTemplate = convertTimestamps(template, [
        "date_created",
        "date_updated",
      ])
      postTemplatesById.value[template.id] = convertedTemplate
    })
  }

  function setPostTemplate(template: IPost) {
    const convertedTemplate = convertTimestamps(template, [
      "date_created",
      "date_updated",
    ])
    postTemplatesById.value[template.id] = convertedTemplate
  }

  function removePostTemplate(templateId: ID<IPost>) {
    delete postTemplatesById.value[templateId]
  }

  // Reactive relationships
  watchEffect(() => {
    // Update project relationships
    Object.values(projectsById.value).forEach((project) => {
      project.$customer = customersById.value[project.customer]
      project.$phases = Object.values(phasesById.value[project.id] || {})
      project.$posts = Object.values(postsById.value[project.id] || {})
    })

    // Update phase relationships
    Object.values(phasesById.value).forEach((phaseGroup) => {
      Object.values(phaseGroup).forEach((phase) => {
        phase.$posts = Object.values(postsById.value[phase.id] || {})
      })
    })

    // Update user relationships if exists
    if (currentUser.value) {
      const employee = Object.values(employeesById.value).find(
        employee => employee.user.id === currentUser.value?.id,
      )
      if (employee) {
        currentUser.value.$employee = employee
      }
      if (currentTenant.value) {
        currentUser.value.$tenant = tenantsById.value[currentTenant.value]
      }
    }
  })

  return {
    // State
    currentUser,
    currentTenant,
    project,
    projectsById,
    customersById,
    postsById,
    postTemplatesById,
    phasesById,
    employeesById,
    tenantsById,

    // Getters
    projects,
    tenants,

    // Actions
    setCurrentTenant,
    setProjects,
    setProject,
    removeProject,
    setCustomers,
    setCustomer,
    removeCustomer,
    setPosts,
    setPost,
    removePost,
    setEmployees,
    setEmployee,
    setUser,
    setTenants,
    setTenant,
    removeTenant,
    setPhases,
    setPhase,
    removePhase,
    setPostTemplates,
    setPostTemplate,
    removePostTemplate,
  }
})
