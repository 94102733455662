<template>
  <div>
    <teleport to="body">
      <div v-if="showOptions && mobile">
        <div class="fixed inset-0 z-20 bg-black opacity-50" />
        <div
          class="p-4 border shadow-2xl action-bottom fixed inset-x-0 bottom-0 pb-safe bg-1-static z-30 rounded-t-3xl"
        >
          <div class="flex items-center justify-between mb-6" @click="$emit('close')">
            <p class="text-label-bold-1">
              {{ title ?? $t("shared.labels.actions") }}
            </p>
            <IconExit />
          </div>
          <slot />
        </div>
      </div>
      <div v-if="!mobile">
        <MobileDialog
          :title="title"
          :show="showOptions"
          type="info"
          @close="$emit('close')"
        >
          <slot />
        </MobileDialog>
      </div>
    </teleport>
  </div>
</template>

<script setup lang="ts">
interface Props {
  title?: string
  showOptions: boolean
}

withDefaults(defineProps<Props>(), {
  title: undefined,
  showOptions: false,
})

defineEmits(["close"])
const mobile = inject("isPhone") as Ref<boolean>
</script>
