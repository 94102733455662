<template>
  <div>
    <div
      :class="{
        'flex items-center justify-between rounded-xl bg-2-static px-4 py-3':
          label !== '',
      }"
    >
      <p
        v-if="label !== '' && checkboxLocation == 'right'"
        class="mr-4"
        :class="{
          'text-label-1 text-color-label-1': size == 'medium',
          'text-label-3 text-color-label-3': size == 'small',
        }"
      >
        {{ label }}
      </p>
      <input
        ref="input"
        v-model="fieldValue"
        type="checkbox"
        :name="name"
        class="w-6 h-6 rounded-md"
        :class="{ 'cursor-pointer': !disabled && editing }"
        :disabled="disabled || !editing"
      >
      <p
        v-if="label !== '' && checkboxLocation == 'left'"
        class="ml-4"
        :class="{
          'text-label-1 text-color-label-1': size == 'medium',
          'text-label-3 text-color-label-3': size == 'small',
        }"
      >
        {{ label }}
      </p>
    </div>
    <hr v-if="showDivider" class="border-divider">
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types"
import api from "@/api"
import { useField } from "vee-validate"

const props = withDefaults(
  defineProps<{
    name?: string
    label?: string
    modelValue?: boolean
    disabled?: boolean
    displayValue?: string
    showDivider?: boolean
    checkboxLocation?: "left" | "right"
    size?: "small" | "medium" | "large"
    directusContext?: DirectusContext
  }>(),
  {
    name: "",
    label: "",
    modelValue: false,
    disabled: false,
    displayValue: undefined,
    showDivider: false,
    checkboxLocation: "right",
    size: "medium",
    directusContext: undefined,
  },
)

// watch modelValue and update change using api.directus
const emit = defineEmits(["update:modelValue"])
const editing = inject<boolean>("editing", true)
const input = ref(null)
defineExpose({ input })

const { value: fieldValue } = useField(props.name, value => value, {
  initialValue: props.modelValue,
})

watch(
  () => fieldValue.value,
  async (newValue, oldValue) => {
    emit("update:modelValue", newValue)
    if (newValue === oldValue) { return }
    if (!props.directusContext) { return }
    const { collection, id, property } = props.directusContext
    if (!collection || !id || !property) { return }
    await api.updateGeneric({ collection, id, data: { [property]: newValue } })
  },
)
</script>

<style>
[type="checkbox"]:checked {
  background-image: url("/icon/check.svg") !important;
  background-size: 65% 65% !important;
}
</style>
