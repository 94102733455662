<template>
  <PageLayout v-if="project && tenant && directusContext">
    <template #header>
      <div class="flex items-center">
        <IconArrowBackward @click="router.back()" />
        <p class="ml-4 grow py-2 text-label-bold-1">
          {{ $t("preview") }}
        </p>

        <div class="flex flex-wrap justify-end gap-3">
          <Button
            v-if="!mobile"
            type="primary"
            class="mr-4"
            size="medium"
            @click="goToSendEmail"
          >
            {{ $t("send_via_email") }}
          </Button>
          <DownloadPdfButton
            class="mr-4"
            :project="project"
            :tenant="tenant"
            :user="globalStore.user"
          />
        </div>
      </div>
    </template>

    <div class="max-w-full pt-6">
      <div class="hide-scrollbar mx-auto max-h-[75dvh] overflow-y-auto">
        <ScalablePDFPageNew :project="project" :tenant="tenant" :colors="colors" />
      </div>
    </div>

    <template #footer-mobile>
      <Button type="primary" class="mr-2 w-full" size="large" @click="goToSendEmail">
        {{ $t("send_via_email") }}
      </Button>
    </template>
  </PageLayout>
  <AtomSpinner v-else />
</template>

<script setup lang="ts">
import type { Project, Tenant } from "@/types"
import { getSecondaryColor } from "@/composables/color"
import router from "@/router"
import { useProjectStore } from "@/stores/projectStore"

interface DirectusContext {
  collection: string
  property: string
  id: string
}

const route = useRoute()
const projectStore = useProjectStore()
const globalStore = useGlobalStore()
const quoteId = route.params.quoteId as string
const project = ref<Project>()
const tenant = ref<Tenant>()
const directusContext = ref<DirectusContext | undefined>(undefined)
const colors = ref({ primary: "#0000ff", secondary: "#8888ff", customized: false })

init()

async function init() {
  if (!projectStore.project.id) {
    console.log("Get project from server")
    await projectStore.setProject(quoteId)
  }
  project.value = projectStore.project as Project
  directusContext.value = projectStore.directusContext as DirectusContext

  tenant.value = globalStore.tenant
  colors.value = calculateColor(tenant.value)
}

function calculateColor(tenant?: Tenant) {
  const primary = tenant?.invoice_color ?? "#000000"
  const secondary = getSecondaryColor(primary)
  const customized = primary !== "#000000"
  return { primary, secondary, customized }
}

async function goToSendEmail() {
  await router.push({
    name: "quote.send",
    params: {
      quoteId,
    },
  })
}

const mobile = inject("isPhone") as Ref<boolean>
</script>
