<template>
  <div class="mx-2 flex h-dvh flex-col items-center justify-center bg-1-static">
    <div
      class="border-gray relative mx-4 flex w-full max-w-sm flex-col items-center rounded-2xl border bg-white p-10"
    >
      <ArrowLeftIcon
        v-if="currentRouteNotOptions"
        class="absolute left-0 top-0 ml-4 mt-4 h-6 w-6"
        @click="goBack"
      />

      <img class="w-3/5" src="/img/tredco.svg" alt="Tredco">

      <hr class="mb-5 mt-10 w-full">
      <RouterView />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowLeftIcon } from "@heroicons/vue/24/solid"
import { RouterView, useRouter } from "vue-router"

const router = useRouter()

const currentRouteNotOptions = ref<boolean>(false)
watch(router.currentRoute, () => {
  currentRouteNotOptions.value = router.currentRoute.value.path !== "/auth/login-options"
})

async function goBack() {
  router.go(-1)
}
</script>
