import type { Languages } from "@/types"
import Danish from "@/localization/da-DK/master.json"
import German from "@/localization/de-DE/master.json"
import English from "@/localization/en-US/master.json"
import Finish from "@/localization/fi-FI/master.json"
import French from "@/localization/fr-FR/master.json"
import Norwegian from "@/localization/no-NO/master.json"
import { getLanguageBasedOnIP } from "@/utils/getLanguageBasedOnIp"
import { email, numeric, required } from "@vee-validate/rules"
import { configure, defineRule } from "vee-validate"
import { createI18n } from "vue-i18n"

const messages: Record<Languages, object> = {
  nb: Norwegian,
  en: English,
  fr: French,
  de: German,
  fi: Finish,
  da: Danish,
}

// Set en default language because en because internet speed is so good
// the automaticat detection will be fast enough compared to more
// 3rd world countries
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "en",
  fallbackLocale: "en",
  silentFallbackWarn: true,
  messages,
})

const t = i18n.global.t

// Set up validation rules
defineRule("required", required)
defineRule("email", email)
defineRule("numeric", numeric)
defineRule("decimal", (value: number) => {
  if (isNaN(Number(value))) {
    return false
  }
  return true
})

configure({
  generateMessage: (ctx) => {
    // console.log("🚀 - file: i18n.ts - line 37 - ctx", ctx)
    const key = `validations.${ctx.rule?.name}`
    const message = i18n.global.t(key, { property: ctx.field })
    if (!message) {
      console.warn(`[vee-validate] Missing message for rule "${ctx.rule?.name}"`)
      return ""
    }

    return message as string
  },
})

// Asynchronously update the language
async function updateLanguage() {
  const params = new URLSearchParams(location.search)
  let langFromLocalStorage
    = params.get("language")
    ?? (window.localStorage.getItem("tredco-app-language") as Languages | null)
  if (langFromLocalStorage === null) {
    langFromLocalStorage = (await getLanguageBasedOnIP()).language
    window.localStorage.setItem("tredco-app-language", langFromLocalStorage)
  }

  i18n.global.locale.value = langFromLocalStorage
}

updateLanguage()

export { i18n, t }
