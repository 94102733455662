<template>
  <div>
    <div class="py-6">
      <TopNav
        :title="$t('quote.photos.review_photo')"
        :type="imageStore.status"
        :show-additiol-options="true"
        :hide-save-button="true"
        @back="$router.back()"
        @exit="$router.back()"
      >
        <template #options>
          <PhotoOptions />
        </template>
      </TopNav>
    </div>

    <p class="mt-4 ml-4 text-label-1 text-color-label-1">
      {{ $t("quote.photos.draw_with_finger") }}
    </p>
    <div class="flex items-center justify-center mt-4 bg-1-static">
      <div class="my-4 border rounded-lg border-1">
        <v-stage ref="stageRef" :config="imageStore.stageConfig">
          <v-layer ref="layerRef">
            <v-image
              v-if="imageStore.imageSrc"
              :config="{
                image: imageStore.imageObj,
                width: imageStore.imageObj ? imageStore.imageObj.width : 0,
                height: imageStore.imageObj ? imageStore.imageObj.height : 0,
              }"
            />
          </v-layer>
        </v-stage>
      </div>
    </div>

    <div class="p-4 ation-bottom-position">
      <SaveOrReplacePhoto />
    </div>
  </div>
</template>

<script setup lang="ts">
import type Konva from "konva"
import { useImageStore } from "@/stores/imageStore"

const imageStore = useImageStore()

const showOptions = ref(false)
provide<Ref<boolean>>("showOptions", showOptions)

const stageRef: Ref<Konva.Stage | null> = ref(null)
const layerRef: Ref<Konva.Layer | null> = ref(null)

onMounted(async () => {
  await nextTick()
  imageStore.stageRef = stageRef.value
  imageStore.layerRef = layerRef.value
  imageStore.registerTouchEvents()
})
</script>
