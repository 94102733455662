import type { FirestoreTimestamp } from "@/types/store/data-store.types"

export function isFirestoreTimestamp(value: unknown): value is FirestoreTimestamp {
  return (
    typeof value === "object"
    && value !== null
    && "seconds" in value
    && "nanoseconds" in value
    && typeof (value as FirestoreTimestamp).seconds === "number"
    && typeof (value as FirestoreTimestamp).nanoseconds === "number"
  )
}

export function convertTimestamps<T extends Record<string, unknown>>(
  obj: T,
  tzFields: Array<keyof T>,
): T {
  const result = { ...obj }
  for (const field of tzFields) {
    const value = obj[field]
    if (value) {
      if (isFirestoreTimestamp(value)) {
        ;(result[field] as Date) = new Date(
          value.seconds * 1000 + value.nanoseconds / 1000000,
        )
      }
      else if (typeof value === "string" && !isNaN(Date.parse(value))) {
        ;(result[field] as Date) = new Date(value)
      }
    }
  }
  return result as T
}
