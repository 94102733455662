import type { QuoteStatuses } from "@/types"
import type { Ref } from "vue"
import {
  loadCustomers,
  loadEmployees,
  loadPhases,
  loadPosts,
  loadPostTemplates,
  loadProjects,
  loadTenant,
  loadTenants,
  loadUser,
} from "@/api/firestore/loadCollections"
import { FirebaseFirestore } from "@capacitor-firebase/firestore"
import { initializeApp } from "firebase/app"

interface QueryParams {
  tenant?: never
  projects?: {
    statuses?: QuoteStatuses[]
  }
  customers?: object
  employees?: object
  tenants?: object
  user?: never
  phases?: {
    projectId: string
  }
  posts?: {
    projectId: string
  }
  postTemplates?: never
}

export default function useFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyA8fZ7xFzVYRPREYBuYZA2rkPS-hOgaJqA",
    authDomain: "tredco-app.firebaseapp.com",
    projectId: "tredco-app",
    storageBucket: "tredco-app.appspot.com",
    messagingSenderId: "332061708869",
    appId: "1:332061708869:web:efee7b27791af12b32c92c",
    measurementId: "G-KS2QTC7T83",
  }

  initializeApp(firebaseConfig)

  const projectId: Ref<string | null> = ref(null)
  const dataStore = useDataStore()
  const globalStore = useGlobalStore()
  const subscriptions: Record<string, string | null> = {
    tenant: null,
    projects: null,
    customers: null,
    employees: null,
    tenants: null,
    user: null,
    phases: null,
    posts: null,
    postTemplates: null,
  }

  const loadCollection = async <T extends keyof QueryParams>(
    collection: T,
    newTenantId: string,
    queryParams?: QueryParams[T],
  ) => {
    if (subscriptions[collection]) {
      await FirebaseFirestore.removeSnapshotListener({
        callbackId: subscriptions[collection],
      })
    }

    switch (collection) {
      case "tenant":
        subscriptions.tenant = await loadTenant(newTenantId)
        break
      case "projects":
        subscriptions.projects = await loadProjects(
          newTenantId,
          (queryParams as QueryParams["projects"])?.statuses || [
            "accepted",
            "draft",
            "pending",
          ],
        )
        break
      case "customers":
        subscriptions.customers = await loadCustomers(newTenantId)
        break
      case "employees":
        subscriptions.employees = await loadEmployees(newTenantId)
        break
      case "tenants":
        subscriptions.tenants = await loadTenants()
        break
      case "user":
        subscriptions.user = await loadUser()
        break
      case "phases":
        if (!queryParams || !("projectId" in queryParams)) {
          throw new Error("Project ID is required for loading phases")
        }
        subscriptions.phases = await loadPhases(newTenantId, queryParams.projectId)
        break
      case "posts":
        if (!queryParams || !("projectId" in queryParams)) {
          throw new Error("Project ID is required for loading posts")
        }
        subscriptions.posts = await loadPosts(newTenantId, queryParams.projectId)
        break
      case "postTemplates":
        subscriptions.postTemplates = await loadPostTemplates(newTenantId)
        break
    }
  }

  watch(
    () => globalStore.currentUserId,
    async (newUserId, oldUserId) => {
      if (newUserId !== oldUserId) {
        await loadCollection("user", "")
      }
    },
  )

  watch(
    () => dataStore.currentUser?.active_tenant,
    async (newTenantId, oldTenantId) => {
      if (!newTenantId || !globalStore.currentUserId) { return }
      if (newTenantId !== oldTenantId) {
        dataStore.setCurrentTenant(newTenantId)
        await Promise.all([
          loadCollection("tenant", newTenantId),
          loadCollection("projects", newTenantId),
          loadCollection("customers", newTenantId),
          loadCollection("employees", newTenantId),
          loadCollection("tenants", newTenantId),
          loadCollection("postTemplates", newTenantId),
        ])
      }
    },
    { immediate: true },
  )

  watch(
    projectId,
    async (newProjectId, oldProjectId) => {
      if (!newProjectId || !dataStore.currentUser?.active_tenant) { return }
      if (newProjectId !== oldProjectId) {
        await Promise.all([
          loadCollection("phases", dataStore.currentUser?.active_tenant, {
            projectId: newProjectId,
          }),
          loadCollection("posts", dataStore.currentUser?.active_tenant, {
            projectId: newProjectId,
          }),
        ])
      }
    },
    { immediate: true },
  )

  return {
    subscriptions,
    loadCollection,
    projectId,
  }
}
