import type { Currency, Customer, Tenant, TenantTypes, User } from "@/types"
import api from "@/api"
import { useGlobalStore } from "@/stores/globalStore"
import seperateFullName from "@/utils/seperateFullName"
import { defineStore } from "pinia"

export const StatusValues = [
  "not-started",
  "country",
  "require-phone",
  "provide-code",
  "profile",
  "company",
]

type StatusTypes = (typeof StatusValues)[number] | "logo" | "completed"

interface OnboardingState {
  currency: Currency
  status: StatusTypes
  loading: boolean
  updatingInvoiceColor: boolean
  error: boolean
  mobileNumber: string
  logoName: string
  globalStore: ReturnType<typeof useGlobalStore>
  tenantType: TenantTypes
}

function updateTenantInStore(store: ReturnType<typeof useGlobalStore>, tenant: Tenant) {
  if (store.user && store.tenant) {
    store.tenant = tenant
    for (const employee of store.user.tenants) {
      if (primaryKey(employee.tenant, "id") === primaryKey(tenant, "id")) {
        employee.tenant = tenant
      }
    }
  }
}

export const useOnboardingStore = defineStore("onboarding", {
  state: (): OnboardingState => ({
    currency: "ZAR",
    status: "not-started",
    loading: false,
    updatingInvoiceColor: false,
    mobileNumber: "",
    logoName: "",
    error: false,
    globalStore: useGlobalStore(),
    tenantType: "prod",
  }),
  actions: {
    async loginPhone(mobileNumber: string) {
      this.mobileNumber = mobileNumber
      this.loading = true
      const result = await api.users.sendUserSMSCode(this.mobileNumber)
      if (result) {
        this.status = "provide-code"
      }
      else {
        this.error = true
      }
      this.loading = false
    },
    async verifyCode(code: number) {
      this.loading = true
      const result = await api.users.verifyUserSMSCode({
        code: code.toString(),
        mobile: this.mobileNumber,
        createUser: true,
        tenantType: this.tenantType,
      })
      if (result.status) {
        this.globalStore.loginEmail({
          email: result.user?.email ?? "",
          password: code.toString(),
        })
        this.status = "profile"
        this.loading = false
      }
      else {
        this.error = true
        this.loading = false
      }
    },
    async updateProfile(values: { email: string, fullName: string }) {
      this.loading = true

      let user: User | null = null
      try {
        user = await api.users.updateMe({
          email: values.email,
          first_name: seperateFullName(values.fullName).firstName,
          last_name: seperateFullName(values.fullName).lastName,
        })
        if (user) {
          Object.assign(this.globalStore.user, user)
        }
      }
      catch (error) {
        console.error(error)
        this.error = true
        this.loading = false
        return
      }

      if (this.globalStore.tenant) {
        const tenant = await api.users.updateMyTenant(this.globalStore.tenant.id, {
          email: values.email,
          name: "ny eier",
        })

        if (tenant) {
          updateTenantInStore(this.globalStore, tenant)
        }
      }

      this.status = "company"

      api.callN8nWorkflow({
        path: "/welcome-email",
        tenant_id: this.globalStore.tenant.id,
        first_name: seperateFullName(values.fullName).firstName,
        email: values.email,
        language: "nb",
      })
      this.loading = false
    },
    async updateTenantOwner(owner: Customer) {
      this.loading = true

      const payload = owner as any as Partial<Tenant>

      payload.email = this.globalStore.tenant?.email ?? ""
      payload.phone_number = this.globalStore.user?.phone_number ?? ""
      payload.currency = this.currency
      payload.country_code = this.currency === "NOK" ? "NO" : "ZA"
      if (this.globalStore.tenant) {
        const tenantId = primaryKey(this.globalStore.tenant)
        const tenant = await api.users.updateMyTenant(tenantId, {
          ...payload,
        })

        if (tenant) {
          updateTenantInStore(this.globalStore, tenant)

          this.status = "logo"
        }
      }
      else {
        this.error = true
      }
      this.loading = false
    },
    async updateInvoiceColor(color: string) {
      this.updatingInvoiceColor = true
      const tenantId = primaryKey(this.globalStore.tenant)
      if (tenantId) {
        const tenant = await api.users.updateMyTenant(tenantId, {
          invoice_color: color,
        })
        if (tenant) {
          updateTenantInStore(this.globalStore, tenant)
        }
      }
      this.updatingInvoiceColor = false
    },
    async determineStatus() {
      await this.globalStore.hydrate(false)
      if (this.globalStore.isLoggedIn) {
        this.status = "profile"
      }
    },
    resetState() {
      this.status = "not-started"
      this.loading = false
      this.error = false
    },
  },
})
