<template>
  <h1 class="mb-4 text-xl font-bold">
    {{ getPageTitle }}
  </h1>
  <MobileForm
    v-if="globalStore.loginStatus !== 'waiting-verification'"
    :loading="globalStore.loading"
    @submit="globalStore.loginPhone"
  >
    {{ t("auth.send_sms_code") }}
  </MobileForm>
  <VerifyMobileForm v-else />

  <MessageAlert
    :show="globalStore.showMessageVerificationCodeSentMessage"
    type="success"
    class="w-full"
  >
    {{ t("auth.send_sms_code_message") }}
  </MessageAlert>
  <MessageAlert :show="globalStore.showError" type="error" class="w-full">
    {{ t("auth.send_sms_code_no_user") }}
  </MessageAlert>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useGlobalStore } from "@/stores/globalStore"
import { useRoute } from "vue-router"

const globalStore = useGlobalStore()
globalStore.resetState()

const route = useRoute()
const getPageTitle = computed(() => {
  if (route.query["register-user"] === "true") {
    return t("auth.register")
  }
  else {
    return t("auth.login")
  }
})

const router = useRouter()
watch(
  () => globalStore.isLoggedIn,
  async (isLoggedIn) => {
    if (globalStore.passwordResetStatus === "started") {
      await router.push({ name: "auth.resetPassword" })
    }
    else if (isLoggedIn) {
      await router.push({ name: "quotes" })
    }
  },
)
</script>
@/localization/i18n
