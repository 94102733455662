import { config } from "@/config"
import { Capacitor } from "@capacitor/core"
import { CapacitorUpdater } from "@capgo/capacitor-updater"
import { defineCustomElements } from "@ionic/pwa-elements/loader"
import * as Sentry from "@sentry/vue"
import { createHead } from "@vueuse/head"
import mixpanel from "mixpanel-browser"
import { createPinia } from "pinia"

import { createApp } from "vue"
import Vue3TouchEvents from "vue3-touch-events"
import VueKonva from "vue-konva"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"
import App from "./App.vue"
import { i18n } from "./localization/i18n"
import globalStorePlugin from "./plugins/global-store-plugin"
import toasterPlugin from "./plugins/toasterPlugin"
import router from "./router"
import "./assets/css/styles.css"

if (Capacitor.isNativePlatform()) {
  CapacitorUpdater.addListener("updateAvailable", async (res) => {
    try {
      CapacitorUpdater.set(res.bundle)
    }
    catch (error) {
      console.log(error)
    }
  })

  CapacitorUpdater.notifyAppReady()
}

if (config.isProduction) {
  mixpanel.init(config.mixPanelToken, { debug: true })
  mixpanel.track("App start")
}

const head = createHead()

defineCustomElements(window)
const app = createApp(App)

if (config.isProduction) {
  Sentry.init({
    app,
    logErrors: true,
    dsn: config.sentryDns,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    integrations: [
      // Sentry.replayIntegration()
    ],
    tracesSampleRate: 0.1,
  })
}

app.component("ClipLoader", ClipLoader)
app.use(Vue3TouchEvents)
app.use(VueKonva)
app.use(router)
app.use(head)
app.use(i18n)
app.use(toasterPlugin)

const pinia = createPinia()
app.use(pinia)

app.use(globalStorePlugin)

app.mount("#app")
