<template>
  <div class="relative">
    <slot />
    <div class="absolute z-20 bottom-1 left-2">
      <BaseButton
        v-show="showTextPolishButton"
        variant="primary"
        class="p-1 rounded-md shadow-sm"
        @mousedown.prevent
        @click="showPreview"
      >
        <SparklesIcon v-if="!isLoading" class="w-4 h-4 text-white" />
        <AtomSpinner v-else class="w-4 h-4" color="white" />
      </BaseButton>
    </div>
  </div>

  <GrammarCheckPreview
    :show="showPreviewDialog"
    :original-text="modelValue"
    :available-options="availableOptions"
    @update:show="showPreviewDialog = $event"
    @accept="handleAccept"
  />
</template>

<script setup lang="ts">
import type { TextOperation } from "@/types/text-processing"
import { SparklesIcon } from "@heroicons/vue/24/outline"

interface Props {
  modelValue?: string
  fieldId?: string
  availableOptions?: TextOperation[]
}

const props = withDefaults(defineProps<Props>(), {
  availableOptions: () => ["grammar"],
  modelValue: "",
  fieldId: "",
})
const emit = defineEmits(["update:modelValue"])

const isFocused = ref(false)
const isLoading = ref(false)
const targetElement = ref<HTMLElement | null>(null)
const showPreviewDialog = ref(false)

const isPhone = inject("isPhone", ref(false))

const showTextPolishButton = computed(() => {
  if (isPhone.value) {
    return isExpanded.value
  }
  return isFocused.value
})

onMounted(() => {
  targetElement.value = document.querySelector(
    `input[id="${props.fieldId}"], textarea[id="${props.fieldId}"]`,
  ) as HTMLElement

  useEventListener(targetElement.value, "focus", () => {
    isFocused.value = true
  })

  useEventListener(targetElement.value, "blur", () => {
    isFocused.value = false
  })
})

const isExpanded = ref(false)
provide("isExpanded", isExpanded)
function showPreview() {
  // Hide mobile keyboard by removing focus from the input
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur()
  }
  showPreviewDialog.value = true
}

function handleAccept(text: string) {
  emit("update:modelValue", text)
  // Restore focus to the input/textarea after a short delay
  setTimeout(() => {
    targetElement.value?.focus()
    isExpanded.value = true
  }, 0)
}
</script>

<style scoped>
.dropdown-panel {
  @apply min-w-[200px];
}
</style>
