<template>
  <div
    v-if="globalStore.user.tenants"
    v-on-click-outside="closeTenantDropdown"
    class="relative"
  >
    <div v-if="globalStore.user.tenants.length > 1">
      <button
        class="flex h-10 w-full items-center rounded-lg border border-blue-200 px-4 py-1 text-sm text-blue-600 hover:bg-blue-200 md:flex"
        @click="tenantDropdownOpen = !tenantDropdownOpen"
      >
        {{ globalStore.tenant.name ?? "" }}
        <span class="grow" />
        <div
          class="mb-2 ml-4 flex h-6 w-6 origin-[50%_70%] items-center justify-center transition-transform"
          :class="tenantDropdownOpen ? 'rotate-180' : ''"
        >
          <div class="h-4 w-4 rotate-45 border-b border-r border-blue-600" />
        </div>
      </button>
      <div
        class="absolute left-2 top-11 z-50 w-72 flex-col rounded-lg border border-gray-300 bg-white py-2 drop-shadow-xl"
        :class="tenantDropdownOpen ? 'flex' : 'hidden'"
      >
        <a
          v-for="employee in globalStore.user.tenants"
          :key="employee.tenant.id"
          :class="{
            'bg-gray-200': employee.tenant.id == globalStore.tenant?.id,
            'cursor-pointer hover:bg-gray-300':
              employee.tenant.id != globalStore.tenant?.id,
          }"
          class="px-4 py-2"
          @click="navigateToTenant(employee.tenant.id ?? '')"
        >{{ employee.tenant.name }}</a>
      </div>
    </div>
    <div v-else>
      <div
        class="ml-2 hidden h-10 w-72 items-center px-4 py-1 text-sm text-blue-600 md:flex"
      >
        {{ globalStore.tenant.name }}
        <span class="grow" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVersionStore } from "@/stores/appVersionManagementStore"
import { useGlobalStore } from "@/stores/globalStore"
import { vOnClickOutside } from "@vueuse/components"

const globalStore = useGlobalStore()
const versionStore = useVersionStore()

const tenantDropdownOpen = ref<boolean>(false)

async function navigateToTenant(id: string) {
  if (id === globalStore.tenant.id) { return }
  await globalStore.setTenant(id)
  await versionStore.reloadApp()
}

if (globalStore.tenant.name === "") {
  await globalStore.signOut()
}

function closeTenantDropdown() {
  if (tenantDropdownOpen.value) { tenantDropdownOpen.value = false }
}
</script>
