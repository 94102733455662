<template>
  <div ref="summary">
    <div class="pt-[5.6mm]">
      <div
        class="mb-[5.6mm] rounded-[2.8mm] p-[5.6mm] text-caption-2"
        :style="`background-color: ${colors.primary}1A; color: ${colors.primary};`"
      >
        <div class="grid grid-cols-2 pb-[4.2mm] text-caption-2 text-black opacity-70">
          <div />
          <div>
            <div class="flex justify-between pb-[1.4mm]">
              <div>{{ $t("subtotal") }}</div>
              <div>{{ formatCurrency(totalPrice) }}</div>
            </div>
            <div
              v-if="project.discount_amount > 0"
              class="flex justify-between pb-[1.4mm]"
            >
              <div>{{ $t("quote.settings.discount") }}</div>
              <div>
                {{ formatCurrency(project.discount_amount * -1) }}
              </div>
            </div>
            <div class="flex justify-between pb-[1.4mm]">
              <div>{{ $t("vat") }}</div>
              <div>
                {{ formatCurrency(priceWithDiscount * vatRate) }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between text-heading-3">
          <div>{{ $t("total") }}</div>
          <div>
            <div class="pb-[1.4mm]">
              {{ formatCurrency(priceWithDiscount * (1 + vatRate)) }}
            </div>
            <div class="float-right text-[2.8mm] text-caption-2 text-black opacity-50">
              <div v-if="tenant.vat_registered">
                {{ $t("incl_vat") }}
              </div>
              <div v-else>
                {{ $t("quote.settings.not_vat_registered") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <QuotePDFPaymentPlan
      v-if="project.payment_plan.length > 0"
      v-bind="{
        items: project.payment_plan,
        total: calculateTotalIncVat(project, tenant),
      }"
    />
    <QuotePDFNote
      v-if="project.note !== '' && project.note !== null"
      v-bind="{ colors, project }"
    />
  </div>
</template>

<script setup lang="ts">
import type { Color, PdfProject, PdfTenant } from "@/pdf-generator/types"
import type { Phase, Post } from "@/types"
import { getCountriesConfig } from "@/config/countries"
import { calculateTotalIncVat } from "@/utils/calculateGrandTotal"
import { formatCurrency } from "@/utils/companyUtils"
import { computed, defineEmits, defineProps, ref, watchEffect } from "vue"
import QuotePDFNote from "./QuotePDFNote.vue"
import QuotePDFPaymentPlan from "./QuotePDFPaymentPlan.vue"

const props = defineProps<{
  colors: Color
  project: PdfProject
  tenant: PdfTenant
}>()

const emit = defineEmits<{
  ready: [value: number]
}>()
const vatRate = ref(0)
watchEffect(() => {
  if (!props.tenant.vat_registered) {
    vatRate.value = 0
    return
  }

  vatRate.value = getCountriesConfig().map.get(props.tenant.country_code)?.vatRate || 0
})

const totalPrice = computed<number>(() => {
  return props.project.phases.reduce((total, phase) => {
    return (
      total
      + ((phase as Phase)?.posts as Post[]).reduce((subTotal, post) => {
        return subTotal + +post.unit_price * +post.qty
      }, 0)
    )
  }, 0)
})

const priceWithDiscount = computed<number>(() => {
  return totalPrice.value - Number(props.project.discount_amount || 0)
})

const summary = ref<HTMLElement | null>(null)
watchEffect(() => {
  if (!summary.value) { return }

  emit("ready", summary.value.clientHeight)
})
</script>
