<template>
  <HeaderOnboarding>
    <p class="text-heading-3 text-color-label-1">
      {{ $t("onboarding.require_phone_1") }}
    </p>
    <p class="mt-2 text-label-2 text-color-label-1">
      {{ $t("onboarding.require_phone_2") }}
    </p>
    <form class="w-full" @submit.prevent="submitForm">
      <PhoneInput
        :focus="true"
        name="mobile"
        class="my-5"
        :placeholder="t('auth.mobile_number')"
      />
      <div>
        <Button
          id="submit-phone"
          class="w-full"
          size="large"
          :loading="onboardingStore.loading"
          submit
        >
          {{ $t("onboarding.continue_button") }}
        </Button>
      </div>
    </form>
  </HeaderOnboarding>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useOnboardingStore } from "@/stores/onboardingStore"
import { useForm } from "vee-validate"
import * as Yup from "yup"

const onboardingStore = useOnboardingStore()

const schema = Yup.object().shape({
  mobile: Yup.number().required().typeError(t("customers.phone.validation_message")),
})

const { handleSubmit } = useForm<Yup.InferType<typeof schema>>({
  validationSchema: schema,
})
const submitForm = handleSubmit(async (values) => {
  await onboardingStore.loginPhone(values.mobile.toString())
  onboardingStore.status = "provide-code"
})
</script>
