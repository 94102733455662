<template>
  <PageLayout :outside-padding="false" :form-page="true">
    <template #header>
      <div class="px-4">
        <TopNav
          :title="$t('onboarding.logo_1')"
          :show-additiol-options="false"
          type="edit"
          :devider="false"
          @back="$router.back()"
        />
      </div>
    </template>
    <template #default>
      <Logo
        :button-title="$t('shared.actions.save')"
        :secondary-button-title="$t('cancel')"
        @secondry-clicked="$router.go(-1)"
      />
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import { useTenantStore } from "@/stores/tenantSettingsStore"
import { getColorPalette } from "@/utils/companyUtils"

const tenantStore = useTenantStore()
const logoUrl = ref("")
const colorPreview = ref<HTMLDivElement | null>(null)

onUpdated(() => {
  try {
    const colorHex = tenantStore.selectedColor?.hex.toLowerCase()
    tenantStore.selectedColor = colorHex
  }
  catch (error) {
    console.log()
  }
})

onMounted(async () => {
  try {
    logoUrl.value = await tenantStore.getCompanyLogoUrl
    const img = new Image()
    img.src = logoUrl.value
    img.crossOrigin = "Anonymous"
    img.onload = () => {
      tenantStore.colorPalette = getColorPalette(img)
    }
  }
  catch (error) {
    console.error(error)
  }
  await nextTick()
  if (colorPreview.value) {
    colorPreview.value.style.height = "640px"
  }
})
</script>
