<template>
  <DropdownButton>
    <template #default>
      <div class="p-2">
        <img class="h-4" src="/icon/vertical-ellipsis-blue.svg">
      </div>
    </template>
    <template #dropdownPanel>
      <slot />
    </template>
  </DropdownButton>
</template>

<script setup lang="ts"></script>
